import React, { useState } from "react";
import { Drawer, Button } from "antd";
import { RxHamburgerMenu } from "react-icons/rx";

import SiderMenu from "./SiderMenu"; // Local import
import acedemichat from "../../assets/acedemic-hat.png";
import { GrNotification } from "react-icons/gr";

const SiderDrawer = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  }; // Add curly braces
  const onClose = () => {
    setVisible(false);
  }; // Add curly braces

  return (
    <>
      <div className="m-header">
        {/* <Header /> */}
        <Button onClick={showDrawer} className="hamBurgerMenu_btn">
          <RxHamburgerMenu />
        </Button>
        <div className="header_text">
          <img src={acedemichat} alt="" />
          Progress Tracker
        </div>
        <div className="notificatioIcon-parent">
          <span className="notificationIconTick"></span>
          <GrNotification />
        </div>

        {/* <img src={bill_icon} alt="" className="bill_icon_header" /> */}
      </div>
      <Drawer
        // width={300}
        className="mobile-menu-drawer"
        placement="left"
        closable={false}
        onClose={onClose}
        open={visible}
      >
        <SiderMenu visible={visible} setVisible={setVisible} />
      </Drawer>
    </>
  );
};

export default SiderDrawer;
