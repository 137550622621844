import { getToken, privateAPI, publicAPI } from "../../config/constants";
import { setUser } from "../slices/authSlice";
import store from "../../store";
import { attachToken } from "../../config/constants";
import { notification } from "antd";

interface UpdatePasswordParams {
  email: string;
  code: string;
  newPassword: string;
}

// admin login , Teacher Login , Student Login
// export const adminLogin = async (email: string, password: string) => {
//   const response = await publicAPI.post("/user/login", { email, password });
//   const { token, message } = response.data;
//   attachToken(token);
//   localStorage.setItem("token", token);
//   store.dispatch(setUser({ email, token }));
//   await getSingleUser();
//   return { message, token };
// };

export const adminLogin = async (
  email: string,
  password: string,
  remember: any
) => {
  try {
    const response = await publicAPI.post("/user/login", {
      email,
      password,
      remember,
    });
    const { token, message } = response.data;

    attachToken(token);
    localStorage.setItem("token", token);

    store.dispatch(setUser({ email, token }));

    await getSingleUser();

    notification.success({ message: "Logged in successfully" });

    return { message, token };
  } catch (error: any) {
    notification.error({
      message: "Login Failed",
      description:
        error?.response?.data?.message ||
        "Failed to login. Please check your credentials.",
    });
  }
};

export const forgotPassword = async (email: string) => {
  const response = await publicAPI.post(
    "/user/forget-password",
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // console.log(response.data);
  return response.data;
};

export const updatePassword = async ({
  email,
  code,
  newPassword,
}: UpdatePasswordParams & { code: string }) => {
  // console.log(email, code, newPassword);

  const response = await publicAPI.post(
    "/user/update-password",
    {
      email,
      code,
      newPassword,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // console.log(response.data);
  return response.data;
};

// export const getSingleUser = async () => {
//   const response = await privateAPI.get("/user/get-profile");
//   console.log("user",response.data.user);
//   store.dispatch(setUser({ user: response.data.user, token: getToken() }));
// };
export const getSingleUser = async () => {
  try {
    const response = await privateAPI.get("/user/get-profile");

    // Agar user data mil gaya
    if (response.data.user) {
      // console.log("user", response.data.user);
      store.dispatch(setUser({ user: response.data.user, token: getToken() }));
    }
  } catch (error: any) {
    // Agar error unauthorized hai (status 401)
    if (error.response && error.response.status === 401) {
      console.log("unAuthorized");
      localStorage.removeItem("token");
    } else {
      console.error("Error fetching user data:", error);
    }
  }
};
