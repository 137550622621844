import { useEffect, useState } from "react";
import { Row, Col, Flex, Spin, Collapse, theme } from "antd";
import "../../styles/pages/targets.css";
import SearchableDropdown from "../../components/SearchableDropdown";
import { getLearningapi } from "../../store/services/curriculum";
import { getAllSubject } from "../../store/services/subject";
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsChevronExpand } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const panelStyle = {
  marginBottom: 24,
  background: "#ffffff",
  borderRadius: "8px",
  border: "1px solid #d9d9d9",
};
const panelStyle2 = {
  marginBottom: 24,
  background: "#ffffff",
  borderRadius: "8px",
  border: "none",
  fontSize: "10px",
  // color:"red"
};
const openCloseIcon = (isActive: any) => {
  return isActive ? (
    <MdKeyboardArrowDown style={{ width: "20px", height: "20px" }} />
  ) : (
    <BsChevronExpand style={{ width: "20px", height: "20px" }} />
  );
};

const Targets = () => {
  const { Panel } = Collapse;
  const [isLoading, setIsLoading] = useState(false);
  const [allSubject, setAllSubject] = useState([]);
  const [selectSubject, setSelectSubject] = useState<string>("");
  const [currentSubject, setCurrentSubject] = useState<number | null>(null);
  const [levels, setLevels] = useState<any>([]);

  const navigate = useNavigate();
  const { token } = theme.useToken();

  console.log("selectSubject", { selectSubject, currentSubject });

  useEffect(() => {
    sendData();
  }, []);

  const modifyData = (arr: any) => {
    if (!arr?.length) return;
    const newLevels = arr.map((newLevel: any) => {
      const modules = newLevel?.modules?.map((module: any) => ({
        name: module.moduleName,
        targets: module?.lessonsList?.map((lesson: any) => lesson),
        moduleId: module.moduleId,
        status: module?.status,
      }));

      const { levelId } = newLevel;

      return {
        level: newLevel.levelName,
        modules: modules,
        levelId: levelId,
        status: newLevel.status,
      };
    });
    setLevels(newLevels);
  };

  const sendData = async () => {
    setIsLoading(true);
    const getSub = await getAllSubject(0, 10, "", "active");

    if (getSub) {
      setSelectSubject(getSub.subjectList[0].subjectName);
      const payload = {
        subjectId: Number(getSub.subjectList[0].subjectId),
      };
      const response = await getLearningapi(payload);

      if (response) {
        setCurrentSubject(getSub.subjectList[0].subjectId);
        modifyData(response.newLevelList);
      }
    }
    setIsLoading(false);
  };

  const onChange = async (e: any) => {
    const findClass: any = allSubject.find((item: any) => {
      return e === item.subjectId;
    });
    setSelectSubject(findClass.subjectName);
    setLevels([]);
    setIsLoading(true);

    const response = await getLearningapi({ subjectId: findClass.subjectId });
    if (response) {
      modifyData(response.newLevelList);
    }
    setIsLoading(false);
  };

  const handleTargetClick = (target: string, currentLevelData: any) => {
    navigate(`/lessondetails/${target}`, {
      state: {
        currentLevelData,
        currentModuleId: currentSubject,
        data: allSubject?.find((v: any) => v?.subjectId === currentSubject),
        isTeacher: true,
      },
    });
  };

  return (
    <>
      <main className="target-main-container">
        <Row>
          <Col xs={24}>
            <h1 className="targets">Targets</h1>
          </Col>
          <Col xs={24}>
            <h6 className="target-review">Review and manage the targets.</h6>
          </Col>
          <Col xs={24}>
            <div style={{ marginTop: "10px" }}>
              <SearchableDropdown
                onChange={onChange}
                setFetchedOptions={setAllSubject}
                url="/subject/get-all"
                mode={false}
                placeholder="Search by subject name"
                columns={["subjectName"]}
                type="subject"
                defaultValue={selectSubject}
                params={{ status: "active" }}
              />
            </div>
          </Col>
          <Col xs={24}>
            <div style={{ marginTop: "15px" }}>
              {isLoading ? (
                <Flex
                  align="center"
                  justify="center"
                  style={{ height: "50vh" }}
                  gap="middle"
                >
                  <Spin size="large" />
                </Flex>
              ) : levels?.length > 0 ? (
                <Collapse
                  className="custom_dashboard_collapse"
                  bordered={false}
                  expandIcon={({ isActive }) => openCloseIcon(isActive)}
                  style={{ background: "transparent" }}
                >
                  {levels
                    ?.filter((level: any) => level?.status === "active")
                    ?.map((level: any, index: number) => (
                      <Panel
                        header={level.level}
                        key={index}
                        style={panelStyle}
                      >
                        <Collapse
                          bordered={false}
                          expandIcon={({ isActive }) => (
                            <IoIosArrowDown
                              size={20}
                              className={`arrow-icon ${
                                isActive ? "rotate" : ""
                              }`}
                            />
                          )}
                          style={{ background: token.colorBgContainer }}
                          accordion
                          className="_innerCollapse"
                        >
                          {level?.modules
                            ?.filter(
                              (module: any) => module?.status === "active"
                            )
                            .map((module: any, i: number) => (
                              <Panel
                                key={module?.name}
                                header={module?.name}
                                style={panelStyle2}
                                className="innerpanel"
                              >
                                <ol
                                  style={{ paddingLeft: "1px" }}
                                  className="custom-list"
                                >
                                  {module?.targets?.map(
                                    (lesson: any, i: number) => {
                                      if (lesson.status === "active") {
                                        return (
                                          <li
                                            key={i}
                                            onClick={() => {
                                              handleTargetClick(
                                                lesson?.lessonId,
                                                level
                                              );
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: "black",
                                              fontSize: "14px",
                                              margin: "10px",
                                            }}
                                          >
                                            {lesson?.lessonName}
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                                </ol>
                              </Panel>
                            ))}
                        </Collapse>
                      </Panel>
                    ))}
                </Collapse>
              ) : (
                <div style={{ marginTop: "100px" }}>
                  <h2 style={{ textAlign: "center", color: "gray" }}>
                    No data avaiable
                  </h2>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </main>
    </>
    // <main className="target-main">
    // </main>
  );
};

export default Targets;
