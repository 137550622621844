import { Form, Button, Row, Col, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import React, { useState } from "react";
import { forgotPassword } from "../store/services/adminLogin";
import LoginHeader from "../components/LoginHeader/LoginHeader";
// @ts-ignore
import screenimg from "../assets/screenimg.png";

// @ts-ignore
import progresstracker from "../assets/Blogo.png";

interface PasswordResetFormValues {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: PasswordResetFormValues) => {
    setLoading(true);
    try {
      await forgotPassword(values.email);
      message.success(
        "Instructions to reset your password have been sent to your email."
      );
    } catch (error) {
      console.error(error);
      message.error(
        "Failed to send password reset instructions. Please try again."
      );
    }
    setLoading(false);
  };

  return (
    <div className="credentials-page">
      <LoginHeader />
      <Row>
        <Col xs={24} md={12} lg={12}>
          <div className="tank">
            <div className="auth-form">
              {/* <h1 style={{left:''}}>Forgot Password</h1>
              <p>We’ll send you instructions on how to reset your password.</p> */}
              <Space />
              <Form layout="vertical" onFinish={onFinish}>
                <h1>Forgot Password</h1>
                <p>
                  We’ll send you instructions on how to reset your password.
                </p>
                <Space />
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                  style={{ fontWeight: "500", color: "#344054" }}
                >
                  <input className="input" placeholder="Enter your Email" />
                </Form.Item>
                <Form.Item>
                  {/* <Space> */}
                  <Button
                    className="submit-btn"
                    htmlType="submit"
                    loading={loading}
                  >
                    Submit
                  </Button>
                  {/* </Space> */}
                </Form.Item>
                <p
                  // type="link" icon={}
                  // style={{ background: 'transparent', fontWeight: '600', fontSize: '14px', width: "max-content"}}
                  onClick={() => navigate("/login")}
                  className="back-to-login"
                >
                  <FaArrowLeft />
                  Back to login
                </p>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={0} md={12} lg={12}>
          <div className="image-div">
            <img src={screenimg} alt="" className="screenimg" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
