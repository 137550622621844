import { createSlice } from "@reduxjs/toolkit";
import { studentStateType } from "../../interfaces/student/Student";

const initialState: studentStateType = {
  loadingStudents: false,
  allStudents: [],
  studentsByClassSubject: [],
  allNotes: [],
  singleStudent: null,
};

export const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    setLoadingStudents(state, action) {
      state.loadingStudents = action.payload;
    },

    setAllStudents(state, action) {
      state.allStudents = action.payload;
    },

    setStudentsByClassSubject(state, action) {
      state.studentsByClassSubject = action.payload;
    },

    setStudentBriefAllNotes(state, action) {
      state.allNotes = action.payload;
    },
    setSingleStudent(state, action) {
      state.singleStudent = action.payload;
    },
  },
});

export const {
  setLoadingStudents,
  setAllStudents,
  setStudentsByClassSubject,
  setStudentBriefAllNotes,
  setSingleStudent,
} = studentSlice.actions;

export default studentSlice.reducer;
