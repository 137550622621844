import { Button, Flex, Input, Select, Spin } from "antd";
import StudentPerformanceCard from "../../components/Cards/StudentPerformanceCard";
import GHeader from "../../components/GeneralHeader/GHeader";
import { RiExpandUpDownLine } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import { useEffect, useState } from "react";
import { GoHome } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import useDebounce from "../../Hook/useDebounce";
import { getAllClasses } from "../../store/services/class";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useLocation } from "react-router-dom";
import { getTopFerformerBySubject } from "../../store/services/state";
import { getAllSubject } from "../../store/services/subject";

function DashboardTopPerformers() {
  const [activeItem, setActiveItem] = useState({
    subjectId: null,
    activeTab: "All subjects",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const { topForformer, loading } = useAppSelector((state) => state.states);
  const { classes } = useAppSelector((state) => state.class);
  const [subjects, setSubjects] = useState([
    { subjectId: null, subjectName: "All subjects" }, // Initial "All subjects" option
  ]);
  const [years, setYears] = useState<any>([]);
  const location = useLocation();
  const [student, setStudent] = useState([]);
  const [perPage] = useState(10);
  const currentYear = new Date().getFullYear();
  const [filterState, setFilterState] = useState({
    search: null,
    classId: null,
    year: currentYear,
  });

  const navigate = useNavigate();

  const getYears = () => {
    const startYear = 2000;
    const years_ = [];
    for (let year = startYear; year <= currentYear; year++) {
      years_.push(year);
    }
    setYears(years_);
  };

  useEffect(() => {
    const getSubjects = async () => {
      const response = await getAllSubject(
        currentPage, //page
        perPage, //perpage
        "", //search
        // filter
        "active" //status
      );
      // console.log(response)
      // setSubjects(response.subjectList)

      setSubjects([
        { subjectId: null, subjectName: "All subjects" },
        ...response.subjectList,
      ]);
    };
    getSubjects();
    getAllClasses();
    getYears();
  }, []);

  useEffect(() => {
    const getAboveTargetStudent = async () => {
      const response: any = await getTopFerformerBySubject({
        search: filterState?.search || "",
        classId: filterState?.classId || "",
        subjectId: activeItem?.subjectId || "",
        year: filterState?.year,
        page: currentPage,
        perPage,
      });
      if (response) {
        setCount(response.count);
      }
    };
    getAboveTargetStudent();
  }, [filterState, activeItem, currentPage]);

  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);

  useEffect(() => {
    if (location) {
      setStudent(location?.state?.topPerformer);
    }
  }, [location]);

  const handleClassChange = async (e: any) => {
    setFilterState({
      ...filterState,
      classId: e,
    });
  };

  return (
    <div
      className="mainDiv responsive"
      //  topPerfomerContainer
    >
      <div
        className="_breadcrumb"
        style={{
          marginBottom: 40,
          display: "flex",
          alignItems: "center",
          gap: 3,
        }}
      >
        <GoHome
          size={20}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />{" "}
        <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
        <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
          Dashboard
        </span>{" "}
        <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
        <p
          style={{
            color: "#fff",
            backgroundColor: "var(--secondary-color)",
            padding: "7px",
            borderRadius: "7px",
          }}
        >
          Top Performers
        </p>
      </div>
      <GHeader
        heading="Top Performers"
        headingText="Review and search top performers among students."
      />
      <div className="Custom_tabs_containers">
        {subjects?.map((tab: any, index: any) => {
          return (
            <div
              key={index}
              className={`${
                activeItem?.activeTab === tab.subjectName
                  ? "custom_active_tab"
                  : "custom_tab"
              } custom-tab-all `}
              style={{ cursor: loading ? "not-allowed" : "pointer" }}
              onClick={() => {
                setActiveItem({
                  subjectId: tab.subjectId,
                  activeTab: tab.subjectName,
                });
                setCurrentPage(1);
              }}
            >
              {tab.subjectName}
            </div>
          );
        })}
      </div>
      <div className="fliters_container">
        <Input
          placeholder="Search..."
          onChange={(e) => debouncedFunction(e.target.value, "search")}
          value={filterState.search || undefined}
          prefix={
            <CiSearch
              style={{ width: "20px", height: "20px", color: "#667085" }}
            />
          }
          className="filter_search"
        />
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a class"
            value={filterState.classId}
            onChange={handleClassChange}
            optionFilterProp="children"
            disabled={loading}
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                  // cursor: loading ? "not-allowed" : "pointer"
                }}
              />
            }
          >
            {classes?.length > 0 &&
              classes.map((item: any) => {
                return (
                  <Select.Option value={item.classId}>
                    {item.className}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Current year"
            optionFilterProp="children"
            disabled={loading}
            onChange={(e) => {
              setFilterState({ ...filterState, year: e });
            }}
            suffixIcon={
              <RiExpandUpDownLine
                style={{ width: "20px", height: "20px", color: "#667085" }}
              />
            }
            value={filterState.year}
          >
            {years?.length > 0 &&
              years.map((item: any) => {
                return <Select.Option value={item}>{item}</Select.Option>;
              })}
          </Select>
        </div>
        <div className="select_container">
          <Button
            className="btn_clear"
            style={{ height: "45px" }}
            onClick={() => {
              setFilterState({
                ...filterState,
                classId: null,
                search: null,
                year: currentYear,
              });
            }}
          >
            Clear
          </Button>
        </div>
      </div>
      <div className="performers_main_container">
        <div className="cards">
          {loading ? (
            <Flex
              align="center"
              justify="center"
              style={{ height: "50vh" }}
              gap="middle"
            >
              <Spin size="large" />
            </Flex>
          ) : topForformer && topForformer.length > 0 ? (
            <>
              {topForformer.map((item: any) => {
                return <StudentPerformanceCard key={item?.name} data={item} />;
              })}
              <div className="main_pagination">
                <CustomPagination
                  perPage={perPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  total={count}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "10%",
                fontSize: 20,
              }}
            >
              <p>No data available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardTopPerformers;
