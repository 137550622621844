import { Progress } from "antd";
import React from "react";

interface Props {
  data: any;
}
const ProgressCard: React.FC<Props> = ({ data }) => {
  const per = Number(data?.percentage);

  return (
    <div className="_progressCard">
      <div className="_iconBox">
        <img src={require("../../assets/bag-pack-blue.png")} alt="icon" />
      </div>
      <div className="_detail">
        <h2>{data?.subjectName}</h2>
        <p>{data?.teacher}</p>
      </div>
      <div className="_progressBar">
        {/* Directly pass the rounded number */}
        <Progress percent={Math.round(per)} />
      </div>
    </div>
  );
};

export default ProgressCard;
