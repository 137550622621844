import { useState, useEffect } from "react";
import { Button, Flex, Popconfirm, Spin, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { firstLetterCap } from "../utils";
import { SubjectType } from "../types/interface/subject";

import { getAllSubject } from "../store/services/subject";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  deleteCurriculum,
  getCurriculumApi,
} from "../store/services/curriculum";
import { setCurrentSubjectId } from "../store/slices/Subject/subjectSlice";

import AddTabModal from "../components/Curriculum/AddTabmodal";
import AddCurriculumTabModal from "../components/Curriculum/AddCurriculumtab";

import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { CurriculumType } from "../types/interface/curriculum";
import { checkRoutePermissions } from "../utils/permission";
import NotAccess from "./NotAccess";
import CurriculumDetails from "../components/Curriculum/CurriculumDetails";

const Curriculum = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<any>(null);
  const [subjects, setSubjects] = useState<Array<SubjectType>>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCurriculvisible, setCurriculumVisible] = useState(false);
  const [curriculumData, setCurriculumData] = useState<CurriculumType[]>([]);
  const [isEdit, setIsEdit] = useState<number>(-1);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editObj, setEditObj] = useState<CurriculumType | null>(null);
  const [isLoading, setIsLoading] = useState<string>("main");
  const { user } = useAppSelector((state) => state.auth);

  const { state } = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (state) {
      setActiveItem(state);
      fetchCurriculum(state?.subjectId);
    }
  }, [state]);

  const getData = async () => {
    const _subjects = await getAllSubject(1, 10000000000, "", "active", true);
    if (_subjects?.subjectList?.length > 0) {
      setSubjects(_subjects?.subjectList);
      if (!state) {
        setActiveItem(_subjects?.subjectList[0]);
        await fetchCurriculum(
          activeItem?.subjectId || _subjects?.subjectList[0]?.subjectId
        );
      }
    }
    setIsLoading("");
  };

  const fetchCurriculum = async (subjectId?: number) => {
    setIsLoading("curriculum");
    const response = await getCurriculumApi();
    if (response) {
      const { curriculumList } = response;
      const transformedData: CurriculumType[] = curriculumList.map(
        (curriculum: any) => ({
          Class: curriculum.className,
          focus: curriculum.mindsetToFocusOn,
          practices: curriculum.practicesTopicsToFocusOn,
          primary: curriculum.primaryFocusSubject,
          primaryq: curriculum.primaryFocusQuaidahQuran,
          curriculumId: curriculum.curriculumId,
          classId: curriculum.classId,
        })
      );
      setCurriculumData(transformedData);
    }
    setIsLoading("");
  };

  const handleAddSubject = () => setIsModalVisible(false);

  const handleModalCancel = () => setIsModalVisible(false);

  const handleCurriculumModalCancel = () => {
    setCurriculumVisible(false);
    if (isEdit !== -1) {
      setIsEdit(-1);
    }
    if (editMode) {
      setEditMode(false);
      setEditObj(null);
    }
  };

  const handleIcon = (curriculumId: number) => {
    setCurriculumVisible(true);
    dispatch(setCurrentSubjectId(activeItem?.curriculumId));
  };

  const handleEye = (curriculumId: any) => {
    navigate(`/curriculumdetails/${curriculumId}`);
  };

  const handleEdit = (id: number, obj: CurriculumType) => {
    dispatch(setCurrentSubjectId(activeItem?.curriculumId));
    setCurriculumVisible(true);
    setIsEdit(id);
    setEditMode(true);
    setEditObj(obj);
  };
  const deleteMaqaasid = async (id: any) => {
    setIsLoading("main");
    const res = await deleteCurriculum({ curriculumId: id });
    if (res) {
      getData();
    }
    setIsLoading("");
  };

  const columns = [
    {
      title: "Class",
      dataIndex: "Class",
      key: "Class",
      className: "custom-title-cell",
    },
    { title: "Mindset to focus on", dataIndex: "focus", key: "focus" },
    {
      title: "Practices / Topics to focus on",
      dataIndex: "practices",
      key: "practices",
    },
    { title: "Primary focus subject", dataIndex: "primary", key: "primary" },
    {
      title: "Primary focus Qaidah / Quraan",
      dataIndex: "primaryq",
      key: "primaryq",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",

      render: (_: any, record: CurriculumType) => (
        <div
          style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
        >
          <FiEdit2
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() =>
              !isLoading && handleEdit(Number(record?.curriculumId), record)
            }
          />
          <Popconfirm
            title="Delete Teacher"
            description="Are you sure to delete teacher ?"
            onConfirm={() => {
              deleteMaqaasid(record?.curriculumId);
              // deleteCurriculum(record.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <IoTrashOutline
              className={`${user?.role === "teacher" && "hide_action"}`}
              style={{
                width: "20px",
                height: "20px",
                color: "#D92D20",
                cursor: "pointer",
              }}
            />
          </Popconfirm>
          {/* <EyeOutlined
            style={{
              width: "20px",
              height: "22px",
              cursor: "pointer",
              color: "black",
            }}
            onClick={() => handleEye(record?.curriculumId)}
          /> */}
          {/* <IoTrashOutline
            style={{
              width: "20px",
              height: "20px",
              color: "#D92D20",
              cursor: "pointer",
            }}
          /> */}
        </div>
      ),
    },
  ];

  return checkRoutePermissions("curriculumPage") ? (
    <div className="_curriculumContainer responsive">
      <div className="curriculum_heading">
        <h1>Curriculum</h1>
        {activeItem?.subjectName === "Maqaasid" && (
          <Button
            className="header-btn"
            icon={<IoMdAdd className="mark-read" />}
            onClick={() => setCurriculumVisible(true)}
          >
            Add Maqaasid
          </Button>
        )}
        <Button
          style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
          className="header-btn"
          icon={<IoMdAdd className="mark-read" />}
          onClick={() => !isLoading && setIsModalVisible(true)}
        >
          Add Subject
        </Button>
      </div>
      {isLoading === "main" ? (
        <div className="_isLoading">
          <Spin />
        </div>
      ) : (
        <>
          <div className="_subjectBox Custom_tabs_containers">
            {subjects?.length > 0 ? (
              subjects?.map((subj: any) => {
                return (
                  <div
                    onClick={() => {
                      setActiveItem(subj);
                      fetchCurriculum(subj?.subjectId);
                    }}
                    key={subj?.subjectId}
                    className={
                      activeItem?.subjectId === subj?.subjectId
                        ? "_subjectButtonActive"
                        : "_subjectButton"
                    }
                    style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                  >
                    <p>{firstLetterCap(subj?.subjectName)}</p>
                  </div>
                );
              })
            ) : (
              <div className="subjects_data">No data found</div>
            )}
          </div>
          {isLoading ? (
            <Flex
              align="center"
              justify="center"
              style={{ height: "50vh" }}
              gap="middle"
            >
              <Spin size="large" />
            </Flex>
          ) : subjects.length > 0 && activeItem.subjectName == "Maqaasid" ? (
            <div className="_curriculumTable">
              {curriculumData?.length > 0 ? (
                <Table
                  loading={isLoading === "curriculum"}
                  dataSource={curriculumData}
                  columns={columns}
                  rowKey="Class"
                  bordered
                  pagination={false}
                  scroll={{ x: 1000 }}
                />
              ) : isLoading === "curriculum" ? (
                <div className="_isLoading">
                  <Spin />
                </div>
              ) : (
                activeItem && (
                  <div className="_emptyCurriculum">
                    <div
                      className="_addCurriculumButton"
                      onClick={() => handleIcon(activeItem?.curriculumId)}
                    >
                      <IoMdAdd color="#000" size={40} />
                      <p>Click here to add a Curriculum</p>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            <>
              <CurriculumDetails cId={activeItem.subjectId} data={activeItem} />
            </>
          )}
        </>
      )}

      <AddTabModal
        isModalVisible={isModalVisible}
        handleOk={handleAddSubject}
        handleCancel={handleModalCancel}
        getData={getData}
      />
      <AddCurriculumTabModal
        isCurriculvisible={isCurriculvisible}
        handleCancel={handleCurriculumModalCancel}
        fetchCurriculum={() => fetchCurriculum(activeItem?.subjectId)}
        editMode={editMode}
        editObj={editObj}
      />
    </div>
  ) : (
    <NotAccess />
  );
};

export default Curriculum;
