import { Button, Form, Input, Select } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const AddTarget = () => {
  return (
    <div className="teachers_container">
      <div className="header">
        <div className="header_left">
          <div className="title_container">
            <p className="title_text">Add a Target</p>
          </div>
          <p className="desc">Review and mange the target</p>
        </div>
      </div>

      <Form {...formItemLayout}>
        <Form.Item label="Input" name="Target name">
          <Input />
        </Form.Item>

        <Form.Item label="Choose subject" name="InputNumber">
          <Select mode="tags" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="Choose level" name="TextArea">
          <Select mode="tags" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="Chosse lesson" name="Mentions">
          <Select mode="tags" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Target duration"
          name="Target duration"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <Select />
        </Form.Item>

        <Form.Item
          label="Target duration"
          name="Cascader"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <Select mode="tags" style={{ width: "100%" }} />

          <Select mode="tags" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="Materials needed" name="Materialsneeded">
          <Input />
        </Form.Item>

        <Form.Item label="plan" name="Plan">
          <Input />
        </Form.Item>

        <Form.Item label="Resource" name="resoource">
          <Input />
        </Form.Item>

        <Form.Item label="Links" name="links">
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#26252A",
                width: "50%",
              }}
              htmlType="submit"
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#26252A",
                color: "white",
                width: "50%",
              }}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddTarget;
