import React from "react";
import { Pagination } from "antd";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";
import { isTab } from "../../config/constants";
import useWindowDimensions from "../../Hook/useWindowDimensions";

const CustomPagination = ({
  currentPage,
  setCurrentPage,
  total,
  perPage = 10,
}: any) => {
  // const totalPages = Math.ceil(total / 8);
  const totalPages = Math.ceil(total / perPage);
  const { windowWidth } = useWindowDimensions();

  const prevPageHandler = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPageHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="custom_pagination_container">
      {windowWidth > 980 && (
        <div
          className={
            currentPage === 1 ? "disabled_change_btn" : "page_change_btn"
          }
          onClick={prevPageHandler}
        >
          <IoMdArrowBack fontSize={"20px"} /> {!isTab && <span>Previous</span>}
        </div>
      )}

      {windowWidth < 980 ? (
        <>
          <div className="current_page_display">
            <div className={currentPage === 1 ? "changesBtn" : ""}>
              <IoMdArrowBack
                onClick={() => {
                  prevPageHandler();
                }}
                fontSize={"20px"}
                cursor={"pointer"}
              />
            </div>
            Page {currentPage} of {totalPages}
            <div className={currentPage === totalPages ? "changesBtn" : ""}>
              <IoMdArrowForward
                onClick={() => {
                  nextPageHandler();
                }}
                fontSize={"20px"}
                cursor={"pointer"}
              />
            </div>
          </div>
        </>
      ) : (
        <Pagination
          // defaultPageSize={8}
          // defaultPageSize={10}
          pageSize={perPage ? perPage : 10}
          current={currentPage}
          total={total}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
      {windowWidth > 980 && (
        <div
          className={
            currentPage === totalPages
              ? "disabled_change_btn"
              : "page_change_btn"
          }
          onClick={nextPageHandler}
        >
          {windowWidth > 980 && <span>Next</span>}{" "}
          <IoMdArrowForward fontSize={"20px"} />
        </div>
      )}
    </div>
  );
};

export default CustomPagination;
