import { Progress, Spin } from "antd";
import React from "react";
import { FaArrowTrendUp } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface progressPercent {
  percent?: number;
  btn?: boolean;
  currentLevel?: string;
  level?: string;
  btnData?: string;
  link?: string;
  loading?: boolean;
}

const SubjectLevel: React.FC<progressPercent> = ({
  percent,
  btn,
  currentLevel,
  level,
  btnData,
  link,
  loading,
}) => {
  return (
    <div className="learning_Card_child">
      {loading ? (
        <div className="student_subject_level_loading_box">
          <Spin className="student_custom_spinner" />
        </div>
      ) : (
        <div className="card_learning">
          <div className="pro_leaning">
            <Progress
              size={70}
              percent={percent}
              strokeWidth={10}
              strokeColor="var(--primary-color)"
              type="circle"
              trailColor="black"
              format={(percent) => `${percent}%`}
            />
          </div>
          <div className="card_learning_text">
            <p>{level}</p>
            <h1>{currentLevel}</h1>
          </div>
        </div>
      )}
      {/* {
        btn &&
        <div>
          <Link to={link || "#"}>
            <span className="card_learning_btn">
              <FaArrowTrendUp color="#BDE556" />
              <span style={{ marginLeft: "10px" }}>{btnData}</span>
            </span>
          </Link>
        </div>
      } */}
    </div>
  );
};

export default SubjectLevel;
