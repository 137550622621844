export const firstLetterCap = (str: string) => {
  return str ? str[0]?.toUpperCase() + str.slice(1) : str;
};

export const dateFormat = "YYYY-MM-DD";

export const isDarkColor = (color: any) => {
  const [r, g, b] = color?.match(/\d+/g)?.map(Number) || [255, 255, 255];
  const luminance = 0.299 * (r / 255) + 0.587 * (g / 255) + 0.114 * (b / 255);
  return luminance < 0.5;
};
