import { Form, Button, Row, Col, Input } from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import { adminLogin } from "../store/services/adminLogin";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import "../../src/styles/pages/globalResponsive.css";

import screenimg from "../assets/new-login-bg.png";
import LoginHeader from "../components/LoginHeader/LoginHeader";
interface LoginFormValues {
  email: string;
  password: string;
  remember?: string;
}

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: LoginFormValues) => {
    setLoading(true);
    const result = await adminLogin(
      values.email,
      values.password,
      values.remember ? values.remember : null
    );
    console.log(result);
    if (result) {
      navigate("/");
    }
    setLoading(false);
  };

  return (
    <div className="credentials-page">
      <LoginHeader />

      <Row>
        <Col xs={24} lg={12}>
          <div className="tank">
            <div className="auth-form">
              <h1>Log In</h1>
              <p>Welcome back please enter your details</p>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true }]}
                  className="labels"
                >
                  <input
                    type="email"
                    className="input loginInput"
                    placeholder="Enter your Email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true }]}
                  className="labels"
                >
                  <Input.Password
                    className="input loginInput"
                    placeholder="input password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item name="remember">
                  <div className="remember-password">
                    <Checkbox>Remember for 30 Days</Checkbox>
                    <label
                      onClick={() => navigate("/forgotpassword")}
                      htmlFor=""
                      className="labels labels-forgot"
                    >
                      Forgot password
                    </label>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="submit-btn"
                    htmlType="submit"
                    loading={loading}
                  >
                    Log In
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>

        <Col lg={12}>
          <div className="image-div">
            <img src={screenimg} alt="" className="screenimg" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignIn;
