import React, { useEffect, useState } from "react";
import "../../styles/pages/createAssessment.css";
import {
  Form,
  Select,
  Button,
  Steps,
  Collapse,
  theme,
  notification,
  Tooltip,
  Input,
  DatePicker,
} from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import SearchableDropdown from "../../components/SearchableDropdown";
import {
  CreateAssisment,
  createExam,
  getLessonsByModuleId,
  getModuleBySubjects,
} from "../../store/services/createAssisment";
import { getAllTeacher } from "../../store/services/teacher";
import { useNavigate } from "react-router-dom";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import CustomTable from "./CustomTable";
import { FaMinus } from "react-icons/fa";
import { CiCircleCheck } from "react-icons/ci";
import dayjs from "dayjs";

const CreateAssessment: React.FC = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [fetchValue, setFetchValue] = useState([]);
  const [newArrayLesson, setNewArrayLessons] = useState<any>([]);
  const [classId, setClassId] = useState<any>(null);
  const [teachers, setTeachers] = useState([]);
  const [teacherId, setTeacherId] = useState<number>();
  const [examName, setExamName] = useState("");
  const [examDate, setExamDate] = useState(new Date());
  const [subject, setSubject] = useState<any>([]);
  const [modules, setModules] = useState<any>([]);
  const [moduleArray, setModuleArray] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const { Panel } = Collapse;
  type LayoutType = Parameters<typeof Form>[0]["layout"];
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const [isCheckAllLessons, setIsCheckAllLessons] = useState<boolean>(false);

  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout);
  };
  const navigate = useNavigate();

  const onChange = (e: any) => {
    const findClass: any = fetchValue.find((item: any) => {
      return e === item.classId;
    });

    setClassId(findClass.classId);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } }
      : null;

  const buttonItemLayout =
    formLayout === "horizontal"
      ? { wrapperCol: { span: 14, offset: 4 } }
      : null;

  const next = () => {
    if (!examName) {
      notification.error({ message: "Exam name is required" });
      return;
    }
    if (!examDate) {
      notification.error({ message: "Exam date is required" });
      return;
    }
    if (current === 0) {
      const res =
        moduleArray?.length > 0 &&
        moduleArray.every((item: any) => {
          return (
            item.modules?.length > 0 &&
            item.modules.every((module: any) => {
              return module?.lessons && module?.lessons?.length > 0;
            })
          );
        });
      if (res) {
        setCurrent(current + 1);
      } else {
        notification.error({ message: "lesson is required" });
      }
    } else if (current === 1) {
      if (teacherId) {
        setCurrent(current + 1);
      } else {
        notification.error({ message: "Teacher id is required" });
      }
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const filterModule = (subjectId: any) => {
    const subjectModules = modules.find(
      (module: any) => module.subjectId === subjectId
    );
    console.log("subjectModules", subjectModules);
    return subjectModules ? subjectModules.modules : [];
  };

  const filterLesson = (subjectId: any, moduleId: any) => {
    const moduleLessons = newArrayLesson.find(
      (lesson: any) =>
        lesson.subjectId == subjectId && lesson.moduleId == moduleId
    );
    return moduleLessons ? moduleLessons.lessons : [];
  };

  console.log("moduleArray", moduleArray);

  const steps = [
    {
      title: "Set class",
      content: (
        <div className="step-two-scroll">
          <h1>Set a Class</h1>
          <Form
            {...formItemLayout}
            layout={formLayout}
            form={form}
            initialValues={{ layout: formLayout }}
            onValuesChange={onFormLayoutChange}
            style={{ maxWidth: formLayout === "vertical" ? "none" : 600 }}
          >
            <Form.Item label="Select class" rules={[{ required: true }]}>
              <SearchableDropdown
                onChange={onChange}
                setFetchedOptions={setFetchValue}
                url="/class/get-all"
                mode={false}
                columns={["className"]}
                placeholder="Search by class name"
                params={{ status: "active" }}
                // type={"monitoring"}
              />
            </Form.Item>
            {subject?.length > 0 && (
              <>
                <Form.Item
                  label="Enter the exam name"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Enter Exam Name(Required)"
                    value={examName}
                    type="text"
                    onChange={(e) => {
                      setExamName(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Enter the exam date"
                  rules={[{ required: true }]}
                >
                  {/* <Input
                    type="text"
                    onChange={(e) => {
                      // setDateName(e.target.value);
                    }}
                  /> */}
                  {/* @ts-ignore */}
                  <DatePicker
                    onChange={(date, dateString) => {
                      console.log(date, dateString);
                      setExamDate(new Date(dateString));
                    }}
                    placeholder="Enter Exam Date(Required)"
                    value={dayjs(examDate)}
                    style={{ width: "100%", border: "1px solid black" }}
                  />
                </Form.Item>
                <Form.Item
                  label="Select subjects"
                  name="subject"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select Subjects"
                    onChange={(e, Option) => {
                      getValue(e, Option);
                      filterSubjects(e);
                      getModule(e);
                      // e.stopPropagation();
                      // form.setFieldValue("subject", undefined);
                    }}
                  >
                    {subject?.length > 0 &&
                      subject?.map((items: any) => (
                        <Select.Option
                          key={items.subjectId}
                          value={items.subjectId}
                        >
                          {items.subjectName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {moduleArray?.length > 0 && (
              <Collapse
                bordered={false}
                activeKey={activeKey}
                onChange={(key) => setActiveKey(key)}
                expandIcon={({ isActive }) => (
                  <IoIosArrowDown
                    size={20}
                    color="rgba(102, 112, 133, 1)"
                    rotate={isActive ? 90 : 0}
                  />
                )}
                style={{
                  background: token.colorBgContainer,
                }}
                accordion
                className="_levelCollapse"
              >
                {moduleArray?.length > 0 &&
                  moduleArray?.map((item: any, subjectIndex: number) => (
                    <Panel
                      key={item.subjectName}
                      className="collapsepanel"
                      header={item.subjectName}
                      extra={
                        <Tooltip placement="bottom" title={"Delete Module"}>
                          <Button
                            onClick={() => {
                              deleteSubject(subjectIndex);
                            }}
                            className="black-button"
                            // style={{
                            //   borderRadius: "8px",
                            //   height: "40px",
                            //   width: "40px",
                            // }}
                            icon={<FaMinus className="icon" size={11} />}
                          />
                        </Tooltip>
                      }
                    >
                      <Select
                        style={{ width: "100%", marginBottom: "25px" }}
                        onChange={(e, Option) => {
                          filterANdSetModule(item.subjectId, Option);
                          setArrayLessons(item.subjectId, Option);
                        }}
                        placeholder="Select Module"
                      >
                        {modules?.length > 0 &&
                          filterModule(item.subjectId)?.map((module: any) => {
                            return (
                              <Select.Option
                                key={module.moduleId}
                                value={module.moduleId}
                              >
                                {`${module?.moduleName} (${module?.levelName})`}
                              </Select.Option>
                            );
                          })}
                      </Select>
                      {item?.modules?.length > 0 && (
                        <Collapse>
                          {item?.modules?.length > 0 &&
                            item.modules.map(
                              (module: any, moduleIndex: number) => {
                                console.log("module==============>", module);
                                return (
                                  <Panel
                                    key={module.moduleId}
                                    className="collapsepanel"
                                    header={module.moduleName}
                                    extra={
                                      <Tooltip
                                        placement="bottom"
                                        title={"Delete Module"}
                                      >
                                        <Button
                                          onClick={() => {
                                            deletModule(
                                              subjectIndex,
                                              moduleIndex
                                            );
                                          }}
                                          className="black-button"
                                          // style={{
                                          //   borderRadius: "8px",
                                          //   height: "40px",
                                          //   width: "40px",
                                          // }}
                                          icon={
                                            <FaMinus
                                              className="icon"
                                              size={11}
                                            />
                                          }
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 5,
                                      }}
                                    >
                                      <Select
                                        mode="multiple"
                                        placeholder="Select Lessons"
                                        value={module?.lessons?.map(
                                          (v: any) => v?.lessonId
                                        )}
                                        onChange={(e, Option) => {
                                          updatedLesson(
                                            subjectIndex,
                                            Option,
                                            moduleIndex,
                                            false,
                                            filterLesson(
                                              item.subjectId,
                                              module.moduleId
                                            )
                                          );
                                        }}
                                        style={{ width: "100%" }}
                                        maxTagCount="responsive"
                                      >
                                        {filterLesson(
                                          item.subjectId,
                                          module.moduleId
                                        )?.map((lesson: any) => (
                                          <Select.Option
                                            key={lesson.lessonId}
                                            value={lesson.lessonId}
                                          >
                                            {lesson.lessonName}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                      {filterLesson(
                                        item.subjectId,
                                        module.moduleId
                                      )?.length !== module?.lessons?.length && (
                                        <Tooltip
                                          placement="bottom"
                                          title={"Select all lessons"}
                                        >
                                          <CiCircleCheck
                                            size={30}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              const allLessons = filterLesson(
                                                item.subjectId,
                                                module.moduleId
                                              )?.map((lesson: any) => ({
                                                children: lesson.lessonName,
                                                value: lesson.lessonId,
                                              }));
                                              updatedLesson(
                                                subjectIndex,
                                                allLessons,
                                                moduleIndex,
                                                true,
                                                filterLesson(
                                                  item.subjectId,
                                                  module.moduleId
                                                )
                                              );
                                              setIsCheckAllLessons(
                                                !isCheckAllLessons
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </div>
                                    <ul>
                                      {module?.lessons?.length > 0 &&
                                        module.lessons.map(
                                          (item: any, lessonIndex: number) => {
                                            return (
                                              <div
                                                key={item.id}
                                                className="points"
                                              >
                                                <li>{item.lessonName}</li>
                                                <div
                                                  onClick={() => {
                                                    deleteLesson(
                                                      subjectIndex,
                                                      moduleIndex,
                                                      lessonIndex
                                                    );
                                                  }}
                                                >
                                                  <MdDelete
                                                    cursor={"pointer"}
                                                    fontSize={"23px"}
                                                    color="red"
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </ul>
                                  </Panel>
                                );
                              }
                            )}
                        </Collapse>
                      )}
                    </Panel>
                  ))}
              </Collapse>
            )}
          </Form>
        </div>
      ),
    },
    {
      title: "Assign to teacher",
      content: (
        <div
          className="step-two-scroll"
          style={{
            padding: "10px",
            height: "60vh",
            overflowY: "scroll",
          }}
        >
          <h1>Assign to teacher</h1>
          <Form
            {...formItemLayout}
            layout={formLayout}
            form={form}
            initialValues={{ layout: formLayout }}
            onValuesChange={onFormLayoutChange}
            style={{ maxWidth: formLayout === "vertical" ? "none" : 600 }}
          >
            <Form.Item label="Select teacher" rules={[{ required: true }]}>
              <Select
                value={teacherId}
                placeholder="Select teachers"
                onChange={(e) => {
                  setTeacherId(e);
                }}
              >
                {teachers?.length > 0 &&
                  teachers.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.firstName} {item.LastName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item {...buttonItemLayout}></Form.Item>
          </Form>
        </div>
      ),
    },
    {
      title: "Review and submit",
      content: (
        <>
          <div className="custom-table-parent">
            {moduleArray?.map((v: any, i: any) => {
              return <CustomTable key={i} tableData={v} />;
            })}
          </div>
        </>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    marginTop: 16,
  };

  const fetchSubjects = async () => {
    try {
      const res = await CreateAssisment({ classId: classId });
      if (res) {
        setSubject(Object.entries(res.data)[0][1]);
        setModuleArray([]);
      }
    } catch (error) {
      console.log("error assesment", error);
    }
  };

  const getModule = async (subjectId: any) => {
    const res = await getModuleBySubjects({ subjectId: subjectId });
    setModules([...modules, { subjectId, modules: res?.data?.Module }]);
    form.setFieldValue("subject", undefined);
  };

  const deleteLesson = (subj: number, module: number, lesson: number) => {
    const arr = [...moduleArray];
    const subject = arr[subj];
    const mod = subject.modules;
    const modules = mod[module];
    const lessons = modules.lessons;
    lessons.splice(lesson, 1);
    modules.lessons = lessons;
    mod.splice(module, 1, modules);
    subject.modules = mod;
    arr.splice(subj, 1, subject);
    setModuleArray(arr);
  };

  const getValue = async (data: number, option: any) => {
    setModuleArray([
      ...moduleArray,
      {
        classId: classId,
        subjectName: option.children,
        subjectId: data,
        modules: [],
      },
    ]);
  };

  const filterSubjects = (id: any) => {
    const filter = subject.filter((item: any) => item.subjectId !== id);
    setSubject(filter);
  };

  const filterANdSetModule = async (subjectId: number, item: any) => {
    const newModulesArray = [...moduleArray];
    const objInd = newModulesArray.findIndex(
      (obj) => obj.subjectId === subjectId
    );
    if (objInd !== -1) {
      const newObj = { ...newModulesArray[objInd] };
      const moduleExists = newObj.modules.some(
        (module: any) => module.moduleId === Number(item.key)
      );
      if (!moduleExists) {
        newObj.modules = [
          ...newObj.modules,
          {
            moduleName: item.children,
            moduleId: Number(item.key),
            lessons: [],
          },
        ];
        newModulesArray.splice(objInd, 1, newObj);
        setModuleArray(newModulesArray);
      }
    }
  };

  const setArrayLessons = async (data: any, option: any) => {
    const findLessons = await getLessonsByModuleId({ moduleId: option.key });
    setNewArrayLessons([
      ...newArrayLesson,
      {
        subjectId: data,
        moduleId: Number(option.key),
        lessons: findLessons?.data.lessons,
      },
    ]);
  };

  const updatedLesson = async (
    subjectIndex: number,
    Option: any,
    moduleIndex: number,
    isSelect?: boolean,
    optionArr?: any
  ) => {
    console.log("Yolooo", {
      subjectIndex,
      Option,
      moduleIndex,
      isSelect,
      optionArr,
    });
    const updatedModules = [...moduleArray];
    let modifyLessons = [];
    if (isSelect) {
      modifyLessons = optionArr.map((item: any) => {
        return {
          lessonName: item.lessonName,
          lessonId: item.lessonId,
        };
      });
    } else {
      modifyLessons = Option.map((item: any) => {
        return {
          lessonName: item.children,
          lessonId: item.value,
        };
      });
    }

    updatedModules[subjectIndex].modules[moduleIndex] = {
      ...updatedModules[subjectIndex].modules[moduleIndex], // Preserve existing properties
      lessons: modifyLessons, // Update lessons
    };
    setModuleArray(updatedModules);
  };

  const fetchTeachers = async () => {
    try {
      const res = await getAllTeacher();
      setTeachers(res?.records);
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendData = async () => {
    setLoading(true);
    try {
      const data = {
        teacherId: teacherId,
        classId: classId,
        subjects: moduleArray,
        examName: String(examName),
        examDate: examDate?.toISOString(),
      };
      const res = await createExam(data);
      if (res?.status == 200) {
        navigate("/assessment");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSubject = (subjectIndex: number) => {
    const arr = [...moduleArray];
    form.setFieldValue("subject", undefined);
    const findDeletingObjectId = arr[subjectIndex].subjectId;
    const findObject = arr.find(
      (item: any) => item.subjectId === findDeletingObjectId
    );
    if (findObject) {
      arr.splice(subjectIndex, 1);
      setModuleArray(arr);
      setSubject([...subject, findObject]);
    }
  };

  const deletModule = (subjectIndex: number, moduleIndex: number) => {
    const arr = [...moduleArray];
    const subject = arr[subjectIndex];
    const modules = subject.modules;
    modules.splice(moduleIndex, 1);
    subject.modules = modules;
    arr.splice(subjectIndex, 1, subject);
    setModuleArray(arr);
  };

  useEffect(() => {
    if (classId) {
      fetchSubjects();
    }
  }, [classId]);

  useEffect(() => {
    fetchTeachers();
  }, []);

  return !checkRoutePermissions("assessmentsPagePP") ? (
    <div className="mainDiv">
      <div style={{ width: "100%" }} className="assessment-parent-div">
        <div className="form-parent ">
          <div className="main_parent">
            <div>
              <div>
                <h1 className="prepare-assessment">Assessments</h1>
                {/* <h3 className="some">Some random text here.</h3> */}
              </div>
              <Steps current={current} items={items} className="steps" />
            </div>
            <div className="mian_tab">
              <div style={contentStyle}>{steps[current].content}</div>
            </div>
            <div className="main_pagination">
              {current > 0 && (
                <Button
                  style={{ margin: "5px", width: "48%" }}
                  onClick={() => prev()}
                  disabled={loading}
                >
                  Back
                </Button>
              )}
              {current < steps?.length - 1 && (
                <Button className="next_btn" onClick={() => next()}>
                  Save & Next
                </Button>
              )}
              {current === steps?.length - 1 && (
                <Button
                  onClick={sendData}
                  disabled={loading}
                  loading={loading}
                  className="done-btn"
                >
                  Done
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default CreateAssessment;
