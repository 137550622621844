import { notification } from "antd";
import { privateAPI, publicAPI } from "../../config/constants";
import store from "..";
import {
  setAllTeachers,
  setLoadingTeachers,
} from "../slices/Teachers/teacherSlice";

export const getAllTeacher = async () => {
  try {
    store.dispatch(setLoadingTeachers(true));
    const res = await privateAPI.post("/teacher/get-all");
    if (res) {
      store?.dispatch(setAllTeachers(res?.data?.records));
      return res?.data; // Assuming the data is in res.data
    }
  } catch (error: any) {
    notification?.error({
      message: error?.response?.data?.message || "Server Error",
    });
  } finally {
    store.dispatch(setLoadingTeachers(false));
  }
};
export const getAllTeacherPaginated = async () => {
  try {
    store.dispatch(setLoadingTeachers(true));
    const res = await privateAPI.post("/teacher/get-all-padinated");
    if (res) {
      store?.dispatch(setAllTeachers(res?.data?.records));
    }
    return res?.data; // Assuming the data is in res.data
  } catch (error: any) {
    notification?.error({
      message: error?.response?.data?.message || "Server Error",
    });
  } finally {
    store.dispatch(setLoadingTeachers(false));
  }
};

export const getAllTeacherInClass = async (classId: { classId: number }) => {
  try {
    store.dispatch(setLoadingTeachers(true));
    const res = await privateAPI.post(
      "/teacher/get-all-teachers-in-a-class",
      classId
    );
    if (res) {
      store?.dispatch(setAllTeachers(res?.data?.records));
    }
    return res?.data; // Assuming the data is in res.data
  } catch (error: any) {
    notification?.error({
      message: error?.response?.data?.message || "Server Error",
    });
  } finally {
    store.dispatch(setLoadingTeachers(false));
  }
};

export const getAssignedTeacher = async () => {
  try {
    const res = await publicAPI.get("/teacher/assigned-class");
    return res?.data;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const getAllTeachersList = async (data: {
  page: number;
  perPage: number;
  search?: string;
}) => {
  try {
    store.dispatch(setLoadingTeachers(true));
    const res = await privateAPI.post("teacher/get-all", data);
    if (res) {
      store.dispatch(setAllTeachers(res.data.records));
      return res.data;
    }
  } catch (error) {
    store.dispatch(setLoadingTeachers(false));
    console.log("error teacher", error);
  } finally {
    store.dispatch(setLoadingTeachers(false));
  }
};

export const AssignSubjectsToClass = async (data: any) => {
  try {
    const res = await privateAPI.post("/teacher/assign-class", data);
    if (res) {
      notification.success({
        message: "Subject Assigned Successfully",
      });
    }
    return res.data;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
    console.log("AssignSubects error", error);
  }
};

export const getAllClasses = async (payload?: any) => {
  try {
    const res = await privateAPI.post("/class/get-all", {
      page: 1,
      perPage: 1000,
      ...payload,
    });
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const getAllClass = async () => {
  try {
    const res = await privateAPI.get("/teacher/get-teacher-classes");
    console.log("clas", res);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getClassSubjects = async (classId: number) => {
  try {
    const res = await privateAPI.get(
      `/teacher/get-teacher-class-subjects/${classId}`
    );
    console.log("clas", res);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getStudentsOnClassSubject = async (
  classId: number,
  subjectId: number
) => {
  try {
    const res = await privateAPI.get(
      `/teacher/get-students-on-class-subject/${classId}/${subjectId}`
    );
    console.log("clas", res);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getByClass = async (payload: any) => {
  try {
    const res = await privateAPI.post(
      "/teacher/search-teacher-by-class",
      payload
    );
    store?.dispatch(setAllTeachers(res.data.teachers));
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteTeacher = async (id: number) => {
  try {
    const res = await privateAPI.post("/teacher/delete", { id: id });
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const deleteTeacherSubj = async (data: any) => {
  try {
    const res = await privateAPI.post(
      "/teacher/remove-subject-form-teacher",
      data
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const getStudentAll = async (data: any) => {
  try {
    const res = await privateAPI.post("/student/get-all", data);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
