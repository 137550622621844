import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Spin, notification, Tooltip } from "antd";

import { GoDotFill } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { firstLetterCap } from "../../../utils";
import {
  createProgressNote,
  getProgressHistory,
} from "../../../store/services/class";
import moment from "moment";
import { IoTrashOutline } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";

const { TextArea } = Input;

interface Props {
  open: boolean;
  close: () => void;
  data: any;
  onProgressAdded?: () => void;
}

const ProgressNoteModal: React.FC<Props> = ({
  data,
  open,
  close,
  onProgressAdded,
}) => {
  const [loading, setLoading] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [history, setHistory] = useState<Array<any>>([]);
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    if (open) {
      getHistory();
    }
  }, [open]);

  const getHistory = async () => {
    setLoading("history");
    const res = await getProgressHistory({
      studentId: data?.studentId,
      levelId: data?.levelId,
      moduleId: data?.progress?.moduleId || data?.progress,
      lessonId: data?.lessonId,
    });
    if (res) {
      setHistory(res);
    }
    setLoading("");
  };

  const onFinish = async () => {
    if (note?.trim() === "") {
      notification.error({
        message:
          " Please make sure to fill out the note field before submitting",
      });
      return;
    }
    setLoading("note-btn");

    const payload = {
      notes: note,
      progressId: data?.progress?.progressId,
    };

    const formData = new FormData();
    formData.append("notes", note);
    formData.append("pictures", file?.[0]);
    formData.append("progressId", data?.progress?.progressId);
    const res = await createProgressNote(file ? formData : payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
    if (onProgressAdded) {
      onProgressAdded();
    }
    close();
    if (note?.trim() !== "") setNote("");
    if (history?.length > 0) setHistory([]);
    if (loading) setLoading("");
    if (file) setFile(null);
  };

  const handleCancel = () => {
    close();
    if (note?.trim() !== "") setNote("");
    if (history?.length > 0) setHistory([]);
    if (loading) setLoading("");
    if (file) setFile(null);
  };

  const truncateString = (inputString: string) => {
    const words = inputString?.split("");
    if (words?.length > 15) {
      const start = words.slice(0, 7).join("");
      const end = words.slice(-6).join("");
      return `${start} ... ${end}`;
    } else {
      return inputString;
    }
  };

  return (
    <Modal
      open={open}
      className="_progressNoteModal"
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      centered
    >
      <div className="_modalBody">
        <div className="_modalHeader">
          <div className="_imageBox">
            <img
              src={
                data?.imageUrl
                  ? data?.imageUrl
                  : require("../../../assets/user.png")
              }
              alt="Student"
            />
          </div>
          <div className="_content">
            <h2>{firstLetterCap(data?.studentName)}</h2>
            <div>
              {/* <p>{"Student"}</p>
              <GoDotFill /> */}
              <p>{data?.className}</p>
              <GoDotFill />
              <p>{data?.subjectName}</p>
            </div>
          </div>
        </div>
        <div className="_lessonBox">
          <p>{data?.lessonName}</p>
        </div>
        <div className="_textareaBox">
          <TextArea
            value={note}
            autoSize={{ minRows: 4, maxRows: 1000 }}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Type note here..."
          />
        </div>
        {/* <Button
          style={{ marginTop: " 10px" }}
          className="_addButton"
          type="text"
          icon={
            <FiPlus color="#000" size={20} style={{ marginBottom: "-5px" }} />
          }
        >
          Add additional note
        </Button>
        <br /> */}

        <div>
          <label htmlFor="file-upload" className="file-label">
            {" "}
            <FiPlus
              color="#000"
              size={20}
              style={{ marginBottom: "-5px" }}
            />{" "}
            Attach file
          </label>
          <input
            type="file"
            id="file-upload"
            className="file-input"
            onChange={(e) => setFile(e.target.files)}
          />
        </div>
        {file && (
          <div className="_fileNameRow">
            <p>{truncateString(file?.[0]?.name)}</p>
            <IoTrashOutline
              style={{
                width: "20px",
                height: "20px",
                color: "#D92D20",
                cursor: "pointer",
              }}
              onClick={() => setFile(null)}
            />
          </div>
        )}
        {/* <Button
          style={{ marginTop: " 10px" }}
          className="_addButton"
          type="text"
          icon={
            <FiPlus color="#000" size={20} style={{ marginBottom: "-5px" }} />
          }
        >
          Attach file
        </Button> */}
        {loading === "history" ? (
          <div className="_historyLoadingBox">
            <Spin />
          </div>
        ) : history?.length > 0 ? (
          <div className="_descriptionBox green-scrollbar">
            {history?.map((v, i) => {
              return (
                <div key={i} className="_descriptionCard">
                  <p>{moment(v?.createdDate).format("MMM DD, YYYY")}</p>
                  <div>
                    <Tooltip title={v?.notes || "-"}>
                      <h2>{v?.notes || "-"}</h2>
                    </Tooltip>
                    <div>
                      {!!v?.file && (
                        <RiAttachment2
                          size={20}
                          style={{ cursor: "pointer" }}
                          onClick={() => (window.location.href = v?.file)}
                        />
                      )}
                      <p>{`${v?.firstName} ${v?.lastName}`}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="_historyEmptyBox">
            <p>No History found!</p>
          </div>
        )}
        <div className="_actionButton">
          <Button onClick={handleCancel} disabled={loading === "note-btn"}>
            Cancel
          </Button>
          <Button loading={loading === "note-btn"} onClick={onFinish}>
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProgressNoteModal;
