import React, { useState } from "react";
import { Button, Modal, Form, Select, TimePicker, notification } from "antd";

import dayjs from "dayjs";

import { FiPlus } from "react-icons/fi";
import {
  CreateScheduleFormValues,
  ScheduleType,
} from "../../store/interfaces/schedule";
import { getAllTeacher } from "../../store/services/teacher";
import { useAppSelector } from "../../store/hooks";
import { getSubjectByClass } from "../../store/services/subject";
import { createSchedule, updateSchedule } from "../../store/services/schedule";
import { CiEdit } from "react-icons/ci";
import { LuEye } from "react-icons/lu";
import SearchableDropdown from "../SearchableDropdown";

interface Props {
  editData?: ScheduleType;
  view?: boolean;
}

const CreateSchedule = ({ view, editData }: Props) => {
  const [form] = Form.useForm<CreateScheduleFormValues>();
  const { user } = useAppSelector((state) => state.auth);
  const { allTeachers, loadingTeachers } = useAppSelector(
    (state) => state.teacher
  );
  const { loading } = useAppSelector((state) => state.class);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = async () => {
    setOpenModal(true);
    user?.role === "admin" && (await getAllTeacher());
    if (editData) {
      form.setFieldValue("teacher", editData?.teacherId);
      form.setFieldValue("class", editData?.classId);
      const _teacher: any = allTeachers?.find(
        (v) => v?.id === editData?.teacherId
      );

      setClasses(_teacher?.newClassesList);
      await getSubject();
      form.setFieldValue("subject", editData?.subjectId);
      form.setFieldValue("day", editData?.day);
      form.setFieldValue("time", [
        dayjs(editData?.startTime, "HH:mm"),
        dayjs(editData?.endTime, "HH:mm"),
      ]);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    form.resetFields();
    setClasses([]);
    setSubjects([]);
  };

  const getSubject = async () => {
    setIsLoading(true);
    const subjects = await getSubjectByClass({
      classId: form.getFieldValue("class"),
    });
    setSubjects(subjects?.subjects);
    setIsLoading(false);
  };

  const onFinish = async (values: CreateScheduleFormValues) => {
    if (!values?.time || values.time.length < 2) {
      notification.error({ message: "Time fields are required." });
      return;
    }

    const obj = {
      teacherId: values?.teacher,
      classId: values?.class,
      subjectId: values?.subject,
      day: values?.day,
      startTime: dayjs(values.time[0]).format("HH:mm"),
      endTime: dayjs(values.time[1]).format("HH:mm"),
    };

    if (!obj.startTime || !obj.endTime) {
      notification.error({
        message: "Start time and end time cannot be empty.",
      });
      return;
    }

    setButtonLoading(true);

    const res = editData
      ? await updateSchedule({ ...obj, scheduleId: editData?.scheduleId })
      : await createSchedule(obj);

    if (res) handleCloseModal();
    setButtonLoading(false);
  };

  return (
    <>
      {view ? (
        <LuEye
          size={20}
          style={{ cursor: "pointer" }}
          onClick={handleOpenModal}
        />
      ) : editData ? (
        <CiEdit
          size={20}
          style={{ cursor: "pointer" }}
          onClick={handleOpenModal}
        />
      ) : (
        <Button
          onClick={handleOpenModal}
          type="primary"
          icon={
            <FiPlus color="#fff" size={20} style={{ marginBottom: "-5px" }} />
          }
        >
          Add a Class
        </Button>
      )}
      <Modal
        className="create-schedule-modal"
        centered
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={false}
        width={500}
      >
        <div className="_body">
          <div className="_header">
            <h2>
              {view
                ? "View Schedule"
                : editData
                ? "Update a Schedule"
                : "Add a Schedule"}
            </h2>
            <p>
              Fill out the following information to add a lesson to schedule.
            </p>
          </div>
          <Form
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            form={form}
            onFinish={onFinish}
            requiredMark={false}
            onValuesChange={(value: any) => {
              if (value?.teacher) {
                form.getFieldValue("class") &&
                  form.setFieldValue("class", undefined);
                form.getFieldValue("subject") &&
                  form.setFieldValue("subject", undefined);
                const _teacher: any = allTeachers?.find(
                  (v) => v?.id === value?.teacher
                );
                setClasses(_teacher?.newClassesList);
              }
              if (value.class) {
                form.setFieldValue("subject", undefined);
                getSubject();
              }
            }}
          >
            {user?.role === "admin" && (
              <Form.Item
                name="teacher"
                label="Select Teacher"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  loading={loadingTeachers}
                  disabled={view}
                  placeholder="Select a teacher"
                  style={{ marginBottom: "13px" }}
                  optionFilterProp="children"
                  options={allTeachers?.map((v: any) => {
                    return {
                      value: v?.id,
                      label: `${v?.firstName} ${v?.LastName}`,
                    };
                  })}
                />
              </Form.Item>
            )}
            <Form.Item
              name="class"
              label="Select Class"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                disabled={view || !form.getFieldValue("teacher")}
                placeholder="Select a Class"
                style={{ marginBottom: "13px" }}
                optionFilterProp="children"
                options={classes?.map((v: any) => {
                  return {
                    value: v?.classId,
                    label: v?.className,
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              name="subject"
              label="Select Subject"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                disabled={view || !form.getFieldValue("class")}
                placeholder="Select a Subject"
                loading={loading}
                style={{ marginBottom: "13px" }}
                optionFilterProp="children"
                options={subjects?.map((v: any) => {
                  return {
                    value: v?.subjectId,
                    label: v?.subjectName,
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              name="day"
              label="Select Day"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                disabled={view}
                placeholder="Select a Day"
                style={{ marginBottom: "13px" }}
                optionFilterProp="children"
                options={[
                  {
                    value: "sunday",
                    label: "Sunday",
                  },
                  {
                    value: "monday",
                    label: "Monday",
                  },
                  {
                    value: "tuesday",
                    label: "Tuesday",
                  },
                  {
                    value: "wednesday",
                    label: "Wednesday",
                  },
                  {
                    value: "thursday",
                    label: "Thursday",
                  },
                  {
                    value: "friday",
                    label: "Friday",
                  },
                  {
                    value: "saturday",
                    label: "Saturday",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item name="time" label="Time" className="labels">
              <TimePicker.RangePicker
                disabled={view}
                style={{ width: "100%" }}
                format={"HH:mm"}
              />
            </Form.Item>

            {!view && (
              <Form.Item>
                <div className="_scheduleFooter">
                  <Button onClick={handleCloseModal} disabled={buttonLoading}>
                    Cancel
                  </Button>
                  <Button
                    loading={buttonLoading}
                    disabled={buttonLoading}
                    htmlType="submit"
                  >
                    {editData ? "Update" : "Add"}
                  </Button>
                </div>
              </Form.Item>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CreateSchedule;
