import React, { useEffect, useState } from "react";

import {
  getAdminSchedule,
  getTeacherSchedule,
} from "../../store/services/schedule";
import ScheduleBigScreen from "./ScheduleBigScreen";
import ScheduleMobileScreen from "./ScheduleMobileScreen";
import NotAccess from "../NotAccess";
import { checkRoutePermissions } from "../../utils/permission";
import { useAppSelector } from "../../store/hooks";

interface Props {}

const Schedule: React.FC = ({}: Props) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const times = [
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    user?.role === "admin"
      ? await getAdminSchedule()
      : await getTeacherSchedule({ teacherId: Number(user?.id) });
  };

  return checkRoutePermissions("adminSchedule") ? (
    //  ||
    //   checkRoutePermissions("teacherSchedule")
    <>
      {windowSize > 900 ? (
        <ScheduleBigScreen days={days} times={times} />
      ) : (
        <ScheduleMobileScreen days={days} times={times} />
      )}
    </>
  ) : (
    <NotAccess />
  );
};

export default Schedule;
