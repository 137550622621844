import React from "react";
import { Progress } from "antd";

// @ts-ignore
import AvatarImage from "../../assets/attachment.png";
import { useNavigate } from "react-router-dom";

interface StudentPerformanceCardProps {
  data: {
    class: string;
    className: string;
    name: string;
    percent: number;
    target: string;
    type: string;
  };
}

const StudentPerformanceCard: React.FC<StudentPerformanceCardProps> = ({
  data,
}: any) => {
  const navigate = useNavigate();
  return (
    <div className="student_performance_card">
      <div className="border_green" />
      <div className="card_left">
        <img
          className="AvatarImage"
          src={data?.imageUrl ? data?.imageUrl : AvatarImage}
          alt="AvatarImage"
          onClick={() => {
            navigate("/student-report", {
              state: { id: data?.studentId },
            });
          }}
        />
        <div className="info_container">
          <p className="name">
            {data?.name ?? data.firstName} {data.firstName && data.lastName}
          </p>
          <div className="bottom">
            <p className="value additionalClassStudent">Student</p>
            <span className="green_circle" />
            <p className="value additionalClass">
              {data?.class ?? data.className}
            </p>
            <span className="green_circle" />
            <p className="value additionalStudentClass">{data?.subjectName}</p>
          </div>
        </div>
      </div>

      <div className="card_right">
        <p className="label">Targets met: {data?.progress}</p>
        <Progress
          type="circle"
          strokeColor={
            data?.percentage > 60 ? "var(--primary-color)" : "#F7B27A"
          }
          size={45}
          strokeWidth={10}
          percent={Number(Number(data?.percentage).toFixed(0))}
        />
      </div>
    </div>
  );
};

export default StudentPerformanceCard;
