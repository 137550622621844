import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Progress,
  Select,
  Table,
  Modal,
  Form,
  Space,
} from "antd";

import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { IoTrashOutline } from "react-icons/io5";

import userIcon from "../../../src/assets/user-icon.png";

// @ts-ignore
import addstudent from "../../assets/addstudent.png";

import CustomPagination from "../../components/Pagination/CustomPagination";
import { getSubjectListByClassDropdown } from "../../store/services/subject";
import { getTopFerformer } from "../../store/services/state";
import useDebounce from "../../Hook/useDebounce";
import { useAppSelector } from "../../store/hooks";
import { getAllClasses, getLevelBySubject } from "../../store/services/class";
import moment from "moment";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

interface FilterType {
  search: string | null;
  classId: null | string;
  subjectId: null | string;
  levelId: null | string;
  year: null | number;
}

const TopPerformers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const onSelectChange = (e: any) => {
    // console.log("e", e);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownSubjects, setDropdownSubjects] = useState<any>([]);
  const [dropdownLevels, setDropdownLevels] = useState<any>([]);
  const [dropdownYears, setDropdownYears] = useState<any>([]);
  const [count, setCount] = useState(0);
  const currentYear = moment().year();
  const { classes } = useAppSelector((state) => state.class);
  const { topForformer, loading } = useAppSelector((state) => state.states);
  const [classDropdownLoad, setClassDropdownLoad] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<FilterType>({
    search: null,
    classId: null,
    subjectId: null,
    levelId: null,
    year: currentYear,
  });

  const navigate = useNavigate();

  const getYears = () => {
    const startYear = 2000;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    setDropdownYears(years);
  };

  const handleClassChange = async (e: any) => {
    setFilterState({
      ...filterState,
      classId: e,
      subjectId: null,
      levelId: null,
    });
    setDropdownLevels([]);
    setDropdownSubjects([]);
    const res = await getSubjectListByClassDropdown({ classId: e });
    setDropdownSubjects([
      { subjectName: "All Subjects", subjectId: null },
      ...res.subjects,
    ]);
  };
  const handleSubjectChange = async (e: any) => {
    setFilterState({
      ...filterState,
      subjectId: e,
      levelId: null,
    });
    setDropdownLevels([]);
    const res = await getLevelBySubject({ subjectId: e });
    setDropdownLevels([{ levelName: "All Levels", levelId: null }, ...res]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();

  const getClasses = async () => {
    try {
      setClassDropdownLoad(true);
      await getAllClasses();
      setClassDropdownLoad(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClasses();
    getYears();
  }, []);

  const getAboveTargetStudent = async () => {
    const response: any = await getTopFerformer(
      filterState?.search || "",
      filterState.classId || "",
      filterState.subjectId || "",
      filterState.levelId || "",
      filterState?.year || String(currentYear),
      currentPage,
      10
    );
    setCount(response?.totalCount);
  };
  useEffect(() => {
    getAboveTargetStudent();
  }, [filterState, currentYear, currentPage]);

  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "22%",
      render: (a: any, b: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={b?.avatar ? b?.avatar : userIcon}
              alt="avatar"
              style={{ width: "40px", height: "40px" }}
            />
            <p
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => {
                navigate("/student-report", {
                  state: { id: b?.studentId },
                });
              }}
            >
              {b.firstName}
            </p>
          </div>
        );
      },
    },

    {
      title: "Class",
      dataIndex: "classIndex",
      key: "class",
      width: "22%",
      render(a: any, b: any) {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p style={{ fontSize: "14px" }}>{b.className}</p>
          </div>
        );
      },
    },
    {
      title: "Subjects",
      dataIndex: "subjectName",
      key: "subjects",
      width: "22%",
    },
    {
      title: "At level",
      dataIndex: "levelName",
      key: "levelname",
      width: "22%",
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: "22%",
      render: (a: any, b: any) => {
        return (
          <div>
            <Progress
              percent={Number(Number(b.percentage)?.toFixed(0))}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: () => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IoTrashOutline
              style={{
                width: "20px",
                height: "20px",
                color: "#D92D20",
                cursor: "not-allowed",
                opacity: 0.5,
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="teachers_container">
      <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
      >
        <img src={addstudent} alt="logo" />
        <div className="modalheading">
          <h1>Add Student</h1>
          <div className="pdiv">
            <p className="modoalp">
              Fill out the following information to add a new student to the
              system.
            </p>
          </div>
        </div>
        <div className="form">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name="name" label="Fist Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="last name" label="Last Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="class" label="Select Class" className="labels">
              <Select
                // className='filter_select'
                placeholder="Class 1"
                optionFilterProp="children"
                onChange={onSelectChange}
                suffixIcon={
                  <RiExpandUpDownLine
                    style={{ width: "20px", height: "20px", color: "#667085" }}
                  />
                }
                options={[
                  {
                    value: "1",
                    label: "Class 1",
                  },
                  {
                    value: "2",
                    label: "Class 2",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Progress percent={60} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button className="cancelbtn">Cancel</Button>
                <Button htmlType="reset" className="addbtn">
                  Add
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <div className="fliters_container">
        <Input
          placeholder="Search..."
          prefix={
            <CiSearch
              style={{ width: "20px", height: "20px", color: "#667085" }}
            />
          }
          className="filter_search"
          onChange={(e) => debouncedFunction(e.target.value, "search")}
        />

        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a class"
            value={filterState.classId}
            onChange={handleClassChange}
            optionFilterProp="children"
            notFoundContent={
              classDropdownLoad ? (
                <div style={{ margin: "5px 0px" }}>
                  <Loading />
                </div>
              ) : undefined
            }
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
          >
            {classes?.length > 0 &&
              classes.map((item: any) => {
                return (
                  <Select.Option value={item.classId}>
                    {item.className}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a subject"
            value={filterState.subjectId}
            disabled={dropdownSubjects.length === 0}
            onChange={handleSubjectChange}
            optionFilterProp="children"
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
          >
            {dropdownSubjects?.length > 0 &&
              dropdownSubjects.map((item: any) => {
                return (
                  <Select.Option value={item.subjectId}>
                    {item.subjectName}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Select a level"
            disabled={dropdownLevels.length === 0}
            value={filterState.levelId}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                levelId: e,
              });
            }}
            optionFilterProp="children"
            suffixIcon={
              <RiExpandUpDownLine
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#667085",
                }}
              />
            }
          >
            {dropdownLevels?.length > 0 &&
              dropdownLevels.map((item: any) => {
                return (
                  <Select.Option value={item.levelId}>
                    {item.levelName}
                  </Select.Option>
                );
              })}
          </Select>
        </div>

        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Current year"
            optionFilterProp="children"
            onChange={(e) => {
              setFilterState({ ...filterState, year: e });
            }}
            suffixIcon={
              <RiExpandUpDownLine
                style={{ width: "20px", height: "20px", color: "#667085" }}
              />
            }
            value={filterState.year}
          >
            {dropdownYears?.length > 0 &&
              dropdownYears.map((item: any) => {
                return <Select.Option value={item}>{item}</Select.Option>;
              })}
          </Select>
        </div>
        <div className="select_container">
          <Button
            className="btn_clear"
            style={{ height: "47px" }}
            onClick={() => {
              setFilterState({
                ...filterState,
                classId: null,
                subjectId: null,
                levelId: null,
                year: currentYear,
              });
              setDropdownLevels([]);
              setDropdownSubjects([]);
            }}
          >
            Clear
          </Button>
        </div>
      </div>

      <div className="table_container">
        {topForformer && (
          <Table
            className="custom_table_design"
            dataSource={topForformer}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        )}
      </div>
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={count}
      />
    </div>
  );
};

export default TopPerformers;
