import React, { useState } from "react";

interface generateReport {
  name: string;
  identifier?: string;
  setPoints?: any;
  view?: boolean;
  value?: string | number;
}
const GeneratorCard = ({
  name,
  identifier,
  setPoints,
  view,
  value,
}: generateReport) => {
  const [id, setId] = useState(1);
  const [numb, setNumb] = useState([
    {
      number: 1,
      id: 1,
    },
    {
      number: 2,
      id: 2,
    },
    {
      number: 3,
      id: 3,
    },
    {
      number: 4,
      id: 4,
    },
    {
      number: 5,
      id: 5,
    },
  ]);
  return (
    <div className="generateCard_main">
      <div className="generateCard_text">{name}</div>
      <div className="generateCard_numb_parent">
        {numb.length > 0 &&
          numb.map((item: any) => {
            return (
              <div
                key={item.id}
                onClick={() => {
                  if (!view && identifier) {
                    setId(item.id);
                    setPoints((prev: any) => ({
                      ...prev,
                      [identifier]: item?.number,
                    }));
                  }
                }}
                className={`generateCard_numb ${
                  view
                    ? item.id == value && "generateCard_numb_active"
                    : item.id == id && "generateCard_numb_active"
                }`}
                style={{ cursor: view ? "not-allowed" : "pointer" }}
              >
                {item.number}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GeneratorCard;
