import { useCallback, useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import "../../styles/pages/classesOverview.css";
import { Button, Input, Table, Tag, Popconfirm } from "antd";
import { CiSearch } from "react-icons/ci";
import { FiEdit2 } from "react-icons/fi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { debounce } from "lodash";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useAppSelector } from "../../store/hooks";
import { Flex, Spin } from "antd";
import {
  addArchive,
  fetchByClassName,
  getAllClasses,
  removeSubjectFromClass,
} from "../../store/services/class";
import GHeader from "../../components/GeneralHeader/GHeader";
import ClassModal from "../../components/Modals/modalClasses/modalClasses";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { TiDelete } from "react-icons/ti";
import { MdOutlineArchive } from "react-icons/md";

const ClassesOverview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [className, setClassName] = useState<string | null>(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [data, setData] = useState([]);
  const { classes, count, loading } = useAppSelector((state) => state.class);
  const [currentFilter, setCurrentFilter] = useState("");
  const [sortOption, setSortOption] = useState("Sort");
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const [perPage] = useState(8);

  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
  }, []);

  useEffect(() => {
    fetchAllClasses();
  }, [currentPage]);

  useEffect(() => {
    const class1: any = classes;
    setFilteredClasses(class1);
  }, [classes]);

  const columns = (navigateToCurriculum: any) => {
    const baseColumns = [
      {
        title: "Classes",
        dataIndex: "className",
        key: "className",
        width: "22%",
        render: (t: string, record: any) => (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              record.status === "active" &&
                navigate(`/classes-progress/${record?.classId}`);
            }}
          >
            {t}
          </p>
        ),
      },
      ...(user?.role !== "teacher"
        ? [
            {
              title: "Next Class",
              dataIndex: "progressClassName",
              key: "progressClassName",
              width: "22%",
              render: (text: any) => <p>{text || "N/A"}</p>,
            },
          ]
        : []),
      {
        title: "No. of Teachers",
        dataIndex: "teachers",
        key: "teachers",
        width: "20%",
        render: (_: any, record: any) => {
          if (record?.teacherList?.length > 0) {
            return (
              <Tooltip
                title={
                  <div>
                    {record?.teacherList?.map((item: any, index: any) => (
                      <Tag
                        key={index}
                        style={{
                          backgroundColor: "var(--primary-color)",
                          margin: "5px",
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        {item.firstName} {item.lastName}
                      </Tag>
                    ))}
                  </div>
                }
                overlayInnerStyle={{
                  backgroundColor: "#F7F7F7",
                  border: "none",
                }}
              >
                <Tag
                  style={{
                    width: "30px",
                    padding: "5px",
                    marginLeft: "42px",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "var(--secondary-color)",
                    color: "white",
                  }}
                >
                  {record?.teacherList?.length}
                </Tag>
              </Tooltip>
            );
          } else {
            return (
              <p
                style={{ margin: "0 auto", position: "relative", left: "42px" }}
              >
                {"N/A"}
              </p>
            );
          }
        },
      },
      {
        title: "No. of Students",
        dataIndex: "students",
        key: "students",
        width: "22%",
        render: (_: any, record: any) => {
          if (record?.studentList?.length > 0) {
            return (
              <Tooltip
                title={
                  <div>
                    {record?.studentList?.map((item: any, index: any) => (
                      <Tag
                        key={index}
                        style={{
                          backgroundColor: "var(--primary-color)",
                          margin: "5px",
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        {item.firstName} {item.lastName}
                      </Tag>
                    ))}
                  </div>
                }
                overlayInnerStyle={{
                  backgroundColor: "#F7F7F7",
                  border: "none",
                }}
              >
                <Tag
                  style={{
                    width: "30px",
                    padding: "5px",
                    marginLeft: "42px",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "var(--secondary-color)",
                    color: "white",
                  }}
                >
                  {record?.studentList?.length}
                </Tag>
              </Tooltip>
            );
          } else {
            return (
              <p
                style={{ margin: "0 auto", position: "relative", left: "42px" }}
              >
                {"N/A"}
              </p>
            );
          }
        },
      },
      {
        title: "Subjects",
        dataIndex: "subjects",
        key: "subjects",
        width: "22%",
        render: (subjects: any[], record: any) => {
          if (!subjects || subjects.length === 0) {
            return (
              <Tag
                style={{
                  width: "30px",
                  padding: "5px",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "var(--secondary-color)",
                  color: "white",
                }}
              >
                0
              </Tag>
            );
          }
          return (
            <Tooltip
              title={
                <div>
                  {subjects?.map((item: any, index: any) => (
                    <Tag
                      key={index}
                      style={{
                        backgroundColor: "var(--primary-color)",
                        margin: "5px",
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      {item.subjectName}
                      <TiDelete
                        style={{ cursor: "pointer" }}
                        size={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeSubject(item, record);
                        }}
                      />
                    </Tag>
                  ))}
                </div>
              }
              overlayInnerStyle={{ backgroundColor: "#F7F7F7", border: "none" }}
            >
              <Tag
                style={{
                  width: "30px",
                  padding: "5px",
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "var(--secondary-color)",
                  color: "white",
                }}
              >
                {subjects.length}
              </Tag>
            </Tooltip>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "10%",
        render: (text: any, record: any) =>
          record.status === "active" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                padding: "5px",
              }}
            >
              {user?.role === "teacher" ? (
                " "
              ) : (
                <>
                  <FiEdit2
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: isLoading ? "not-allowed" : "pointer",
                    }}
                    onClick={(e) => {
                      e?.stopPropagation();
                      setIsEdit(true);
                      setData(record);
                      setIsModalOpens(true);
                    }}
                  />
                  {/* {record.status === "active" ? ( */}
                  <Popconfirm
                    title="Archive class"
                    description="Are you sure add to archive?"
                    onConfirm={(e) => {
                      e?.stopPropagation();
                      archive(record);
                    }}
                    onCancel={(e) => e?.stopPropagation()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <MdOutlineArchive
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: isLoading ? "not-allowed" : "pointer",
                      }}
                      onClick={(e) => {
                        e?.stopPropagation();
                      }}
                    />
                  </Popconfirm>
                </>
              )}
              <FaArrowTrendUp
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: isLoading ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  record.status === "active" &&
                    navigate(`/classes-progress/${record?.classId}`);
                }}
              />
            </div>
          ) : null,
      },
    ];

    return baseColumns;
  };

  const fetchAllClasses = async (filter = currentFilter) => {
    await getAllClasses({
      page: currentPage,
      perPage: perPage,
      search: className ?? "",
      status: "active",
      // status: filter,
    });
  };

  const archive = async (record: any) => {
    setIsLoading(true);
    const payload = {
      classId: record.classId,
    };
    const data = await addArchive(payload);
    setIsLoading(false);
    fetchAllClasses(currentFilter);
  };

  const navigateToCurriculum = (classId: number) => {
    navigate(`/curriculum`, {
      state: {
        classId: classId,
      },
    });
  };

  const fetchClass = () => {
    fetchAllClasses();
  };

  const SearchByClassName = async () => {
    try {
      // Only proceed if className is not null or empty
      if (!className || className.trim() === "") {
        const res = await getAllClasses({
          page: currentPage,
          perPage: 8,
          search: className ?? "",
        });
        setFilteredClasses(res.records);
        return;
      }

      const payload = {
        page: currentPage,
        perPage: 7,
        search: className,
      };

      const res = await fetchByClassName(payload);
      setFilteredClasses(res.records);

      // Filter results for exact match based on classIndex
      res.records.filter((classItem: any) => {
        // Compare the search term to classIndex rather than className
        return `class-${classItem.classIndex}` === className.trim();
      });

      // setFilteredClasses(exactMatches);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const debouncedSearch = useCallback(
    debounce(() => {
      SearchByClassName();
    }, 1000),
    [className]
  );

  useEffect(() => {
    if (className) {
      debouncedSearch();
      return () => {
        debouncedSearch.cancel();
      };
    } else {
      fetchAllClasses();
    }
  }, [className, debouncedSearch]);

  const removeSubject = async (subject: any, class_: any) => {
    const payload = { subjectId: subject.subjectId, classId: class_.classId };
    await removeSubjectFromClass(payload);
    fetchClass();
  };

  const selectOrders = async (e: any, k: any) => {
    setSortOption(k.value);
    setCurrentPage(1);
    setCurrentFilter(e);
    const data = await getAllClasses({
      page: currentPage,
      perPage: 8,
      search: className ?? "",
      status: "active",
      // status: filter,
      col: k.key,
      sort: k.value,
    });
  };

  return checkRoutePermissions("classesPage") ? (
    <>
      <ClassModal
        setIsModalOpens={setIsModalOpens}
        isModalOpens={isModalOpens}
        isEdit={isEdit}
        modalData={data}
        setIsEdit={setIsEdit}
        fetchClass={fetchClass}
        count={count}
      />

      <div className="mainDiv responsive">
        <div className="mainss classesTable">
          <div className="main_parent">
            <div>
              <GHeader
                heading="Classes Overview"
                headingText=""
                numberOfPeople={`${count} Classes`}
                buttonText="Add a Class"
                onClick={() => {
                  setIsEdit(false);
                  setIsModalOpens(true);
                }}
              />
              <div style={{ marginTop: "8px" }} className="fliters_container">
                <Input
                  placeholder="Search..."
                  onChange={(e) => {
                    setClassName(e.target.value);
                  }}
                  prefix={
                    <CiSearch
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#667085",
                      }}
                    />
                  }
                  className="filter_search"
                />

                <Select
                  value={sortOption}
                  style={{ width: 220, height: 45 }}
                  disabled={loading}
                  onChange={(e: any, k: any) => selectOrders(e, k)}
                  options={[
                    {
                      key: "className",
                      value: "asc",
                      label: "Classes Ascending",
                    },
                    {
                      key: "className",
                      value: "desc",
                      label: "Classes Descending",
                    },
                  ]}
                />
                <Button
                  className="btn_clear"
                  style={{ height: 45 }}
                  onClick={() => {
                    setSortOption("Sort");
                    fetchAllClasses();
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div className="mian_tab" style={{ marginTop: "10px" }}>
              {loading ? (
                <Flex
                  align="center"
                  justify="center"
                  style={{ height: "50vh" }}
                  gap="middle"
                >
                  <Spin size="large" />
                </Flex>
              ) : (
                <div className="table_container">
                  <Table
                    className="custom_table_design"
                    dataSource={filteredClasses}
                    style={{ flex: 1 }}
                    columns={columns(navigateToCurriculum)}
                    pagination={false}
                    scroll={{ x: 600 }}
                    rowKey={"classId"}
                  />
                </div>
              )}
            </div>
            <div className="main_pagination">
              <CustomPagination
                perPage={perPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={count}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotAccess />
  );
};

export default ClassesOverview;
