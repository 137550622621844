import { useEffect, useState } from "react";
import { Button, DatePicker, Spin, Tooltip } from "antd";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { getSubjectByClass } from "../../../store/services/subject";
import { firstLetterCap, isDarkColor } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setActiveSubject,
  setCurrentLevel,
  setLevels,
} from "../../../store/slices/Classes/classSlice";
import { getAllStudentsByClassSubject } from "../../../store/services/student";
import {
  createProgress,
  getLevelBySubject,
  getStudentProgressByLevel,
  updateStudentLevel,
} from "../../../store/services/class";
import { setStudentsByClassSubject } from "../../../store/slices/students/studentSlice";
import {
  LessonType,
  ModuleType,
  ResetStatusType,
  StatusType,
} from "../../../types/interface/level";
import { SubjectType } from "../../../types/interface/subject";
import ProgressNoteModal from "../../../components/Modals/ProgressNoteModal";
import { BiRefresh } from "react-icons/bi";

import { setActiveProcess } from "../../../store/slices/Classes/activeSlice";
import { useSelector } from "react-redux";
import { FiEdit2 } from "react-icons/fi";
import dayjs from "dayjs";

const DesktopView = () => {
  const { subjects, activeSubject, levels, currentLevel } = useAppSelector(
    (state) => state.class
  );
  const { studentsByClassSubject } = useAppSelector((state) => state.student);
  const [isLoading, setIsLoading] = useState<string>("");
  const [updateStatus, setUpdateStatus] = useState<Array<StatusType>>([]);
  const [noteModal, setNoteModal] = useState<boolean>(false);
  const [noteModalData, setNoteModalData] = useState<any>(null);
  const [categoryAdded, setCategoryAdded] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState(
    levels.length ? [levels[0]] : []
  );
  const [activeLevel, setActiveLevel] = useState<any>(null);
  const [selectedLevelIndex, setSelectedLevelIndex] = useState<number>(1);
  const [date, setDate] = useState<any>(new Date());

  // const [isDragging, setIsDragging] = useState(false);
  // const [originStatus, setOriginStatus] = useState(null); // Status of the box where drag starts
  // const [isDragEnabled, setIsDragEnabled] = useState(false); // Tracks if drag is enabled

  // const handleDoubleClick = (status: any) => {
  //   console.log("Double clicked, enabling drag for status:", status);
  //   setIsDragEnabled(true);
  //   if (!originStatus) {
  //     setOriginStatus(status); // Capture the status on double-click
  //   }
  // };

  // const handleMouseDown = (updateObj: any) => {
  //   if (isDragEnabled) {
  //     console.log("Drag started for object:", updateObj);
  //     setIsDragging(true);
  //     _handleStatusUpdate({ ...updateObj, status: originStatus }); // Apply status to the starting box
  //   }
  // };

  // const handleMouseEnter = (updateObj: any) => {
  //   if (isDragging) {
  //     console.log("Dragging over object:", updateObj);
  //     _handleStatusUpdate({ ...updateObj, status: originStatus }); // Apply origin status to the hovered box
  //   }
  // };

  // const handleMouseUp = () => {
  //   console.log("Mouse released, stopping drag.");
  //   setIsDragging(false);
  //   setIsDragEnabled(false); // Disable drag after mouse release
  //   setOriginStatus(null);
  // };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { classId } = useParams();

  const isActive = useSelector((state: any) => state.active.activeProcessing);

  useEffect(() => {
    _getInitial();

    const cleanup = () => {
      dispatch(setActiveSubject(null));
    };

    return cleanup;
  }, [classId, categoryAdded, date]);

  const handleProgressAdded = () => {
    setCategoryAdded((prev) => !prev);
  };

  useEffect(() => {
    if (levels.length && selectedLevels.length === 0) {
      setSelectedLevels([levels[0]]);
      handleChangeLevel(levels[0].levelId);
      // setSelectedLevelIndex(1);
    }
  }, [levels]);

  const _getInitial = async (isSync?: boolean) => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    console.log({ data: date?.toISOString() });
    if (classId) {
      setIsLoading("initial");
      const _subjects = await getSubjectByClass({ classId: Number(classId) });
      if (_subjects?.subjects?.length > 0) {
        dispatch(setActiveSubject(_subjects?.subjects?.[0]));
        const subjectId = _subjects?.subjects?.[0]?.subjectId;
        await getAllStudentsByClassSubject({
          classId: Number(classId),
          subjectId,
        });
        const _level: any = await getLevelBySubject({ subjectId });
        if (_level?.length > 0) {
          setActiveLevel(_level?.[0]);
          await getStudentProgressByLevel({
            levelId: _level?.[0]?.levelId,
            isSync: isSync ? true : false,
            date: date?.toISOString(),
          });
        }
      }
      setIsLoading("");
    }
  };

  const _getData = async (_subject: SubjectType, isSync?: boolean) => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    if (classId && _subject) {
      setIsLoading("level");
      const subjectId = _subject?.subjectId;
      await getAllStudentsByClassSubject({
        classId: Number(classId),
        subjectId,
      });
      const _level = await getLevelBySubject({ subjectId });
      if (_level?.length > 0)
        await getStudentProgressByLevel({
          levelId: _level?.[0]?.levelId,
          isSync: isSync ? true : false,
        });
      setIsLoading("");
    }
  };

  const _getProgress = async (levelId: number, isSync?: boolean) => {
    setIsLoading("level");
    await getStudentProgressByLevel({
      levelId,
      isSync: isSync ? true : false,
    });
    setIsLoading("");
  };

  const handleChangeLevel = async (levelId: number, isSync?: boolean) => {
    // setSelectedLevelIndex(index ? index : 1);
    try {
      const selectedLevel = levels.find((lev: any) => lev.levelId === levelId);

      setActiveLevel(selectedLevel);
      // Check if the clicked level is already selected
      const levelIndex = levels.findIndex(
        (lev: any) => lev.levelId === levelId
      );
      setSelectedLevelIndex(levelIndex + 1);
      if (selectedLevel) {
        const index = selectedLevels.findIndex(
          (lev) => lev.levelId === levelId
        );

        // If the level is found and it's not the last one
        if (index !== -1 && index < selectedLevels.length - 1) {
          // Move all levels after the clicked level back to the right container
          const newSelectedLevels = selectedLevels.slice(0, index + 1);
          const levelsToReturn = selectedLevels.slice(index + 1);

          // Update the selected levels
          setSelectedLevels(newSelectedLevels);

          // Add the levels back to available levels (if necessary)
          setLevels((prevLevels: any) => [...prevLevels, ...levelsToReturn]);
        } else if (!selectedLevels.includes(selectedLevel)) {
          // If it's a new level being added
          setSelectedLevels([...selectedLevels, selectedLevel]);
        }
      }

      // Your existing loading logic
      setIsLoading("level");
      dispatch(setCurrentLevel([]));
      await getStudentProgressByLevel({
        levelId,
        isSync: isSync ? true : false,
      });
      setIsLoading("");
    } catch (err) {
      setIsLoading("");
      dispatch(setCurrentLevel([]));
    }
  };

  const getColor = (value: string | null | undefined) => {
    switch (value) {
      case "pass":
        return "rgb(83, 194, 37)";
      case "fail":
        return "rgba(234, 67, 54, 1)";
      case "in-progress":
        return "rgba(255, 217, 102, 1)";
      default:
        return "#fff";
    }
  };

  const _handleStatusUpdate = (obj: StatusType) => {
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId &&
        v?.subjectId === obj?.subjectId &&
        v?.classId === obj?.classId
    );
    if (index !== -1) {
      temp.splice(index, 1, obj);
    } else {
      temp.push(obj);
    }
    setUpdateStatus(temp);
    dispatch(setActiveProcess(true));
  };

  const _handleStatusReset = (obj: ResetStatusType) => {
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId &&
        v?.subjectId === obj?.subjectId &&
        v?.classId === obj?.classId
    );
    if (index !== -1) {
      temp.splice(index, 1);
    }
    setUpdateStatus(temp);
    dispatch(setActiveProcess(true));
  };

  const handleSaveProgress = async () => {
    setIsLoading("progress");

    const res = await createProgress({ progress: updateStatus });
    if (res) {
      const levelRes = await updateStudentLevel({
        classId: classId,
        subjectId: activeSubject?.subjectId,
      });

      if (levelRes) {
        updateStatus.length > 0 &&
          (await _getProgress(updateStatus[0].levelId, true));
        setUpdateStatus([]);
        dispatch(setActiveProcess(false));
      }
      await getAllStudentsByClassSubject({
        classId: Number(classId),
        subjectId: activeSubject?.subjectId,
      });
    }
    setIsLoading("");
  };

  const handleOpenNoteModal = (obj: any) => {
    setNoteModal(true);
    setNoteModalData(obj);
  };
  const handleCancelNoteModal = () => {
    setNoteModal(false);
    setNoteModalData(null);
  };
  const moveBack = () => {
    if (isActive) {
      const confirmNavigation = window.confirm(
        "you have unsaved progress are you sure you want to navigate"
      );
      if (!confirmNavigation) {
        return; // Prevent navigation if the user cancels
      }
      dispatch(setActiveProcess(false));
    } else {
      navigate(-1);
    }
  };
  const moveTabs = (subj: any) => {
    if (isActive) {
      const confirmNavigation = window.confirm(
        "you have unsaved progress are you sure you want to navigate"
      );
      if (!confirmNavigation) {
        return; // Prevent navigation if the user cancels
      }
      dispatch(setActiveProcess(false));
      setUpdateStatus([]);
    } else {
      setSelectedLevels([]);
      _getData(subj);
      dispatch(setActiveSubject(subj));
    }
  };

  const handleTargetClick = (
    target: number,
    currentLevelData: any,
    currentModuleId: any,
    modules: any
  ) => {
    navigate(`/lessondetails/${target}`, {
      state: {
        currentLevelData: {
          ...currentLevelData,
          level: currentLevelData?.levelName,
          modules: modules?.map((v: any) => {
            return {
              ...v,
              name: v?.moduleName,
            };
          }),
        },
        currentModuleId,
        data: activeSubject,
      },
    });
  };

  return (
    <div className="_progressContainer">
      <div className="_progressHeader">
        <div className="_headingBox">
          <h2>
            {" "}
            <IoIosArrowRoundBack
              style={{ marginBottom: "-7px", cursor: "pointer" }}
              size={35}
              onClick={() => moveBack()}
            />{" "}
            Classes
          </h2>
          <p>Review and manage the classes.</p>
        </div>
      </div>

      <div className="_subjectContainer">
        {subjects
          ?.slice()
          ?.sort((a: any, b: any) => a?.sortNumber - b?.sortNumber)
          ?.map((subj) => {
            return (
              <div
                onClick={() => {
                  // currentLevel.length > 0 &&
                  moveTabs(subj);
                }}
                key={subj?.subjectId}
                className={
                  activeSubject?.subjectId === subj?.subjectId
                    ? "_subjectButtonActive"
                    : "_subjectButton"
                }
                style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
              >
                <p>{firstLetterCap(subj?.subjectName)}</p>
              </div>
            );
          })}
      </div>
      {activeLevel?.levelId && (
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {/* @ts-ignore */}
          <DatePicker
            value={dayjs(date)}
            onChange={(date, dateString) => {
              setDate(date);
            }}
          />
          {/* <Button
            loading={isLoading === "level"}
            onClick={() => _getProgress(activeLevel?.levelId, true)}
          >
            Sync {activeLevel?.levelName}
          </Button> */}
        </div>
      )}

      {isLoading === "initial" ? (
        <div className="_loadingSpinner">
          <Spin />
        </div>
      ) : subjects?.length > 0 ? (
        <section>
          <div className="_userSection">
            <div className="_top">
              {/* ****************************************************************** */}
              {activeSubject?.subjectName && (
                <p
                  style={{
                    fontWeight: 500,
                    wordBreak: "break-word",
                    marginBottom: "10px",
                  }}
                >
                  {activeSubject?.subjectName}
                </p>
              )}
              {updateStatus?.length > 0 && (
                <>
                  <Button
                    loading={isLoading === "progress"}
                    onClick={handleSaveProgress}
                  >
                    Save Progress
                  </Button>
                  <Button
                    onClick={() => {
                      setUpdateStatus([]);
                      dispatch(setActiveProcess(false));
                    }}
                  >
                    Clear
                  </Button>
                </>
              )}
            </div>

            {studentsByClassSubject?.map((v, i) => {
              const bg = i % 2 == 1;
              return (
                <div
                  className="_userBox"
                  key={i}
                  style={{
                    backgroundColor: bg ? "rgba(250, 251, 253, 1)" : "#fff",
                  }}
                >
                  <p>
                    {firstLetterCap(v?.firstName)} {firstLetterCap(v?.lastName)}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="_progressSection">
            <div
              className="_levelContainer"
              style={{
                left: `-${
                  (levels?.slice(0, selectedLevelIndex)?.length - 1) * 34
                }px`,
              }}
            >
              {levels?.slice(0, selectedLevelIndex)?.map((v: any, i: any) => {
                return (
                  <div
                    key={i}
                    className="_levelBox"
                    style={{
                      backgroundColor: `${v?.color}`,
                      cursor: isLoading ? "not-allowed" : "pointer",
                    }}
                    onClick={() => !isLoading && handleChangeLevel(v.levelId)}
                  >
                    <span
                      style={{
                        color: isDarkColor(v?.color) ? "white" : "black",
                      }}
                    >
                      {v?.levelName}
                    </span>
                  </div>
                );
              })}
            </div>

            <div className="_levelContent">
              <div
                className="_lcHeader"
                style={{
                  backgroundColor:
                    selectedLevels.length > 0
                      ? `${selectedLevels[selectedLevels.length - 1]?.color}`
                      : "transparent",
                }}
              >
                <p
                  style={{
                    color: isDarkColor(
                      `${selectedLevels[selectedLevels.length - 1]?.color}`
                    )
                      ? "white"
                      : "black",
                  }}
                >
                  {selectedLevels[selectedLevels.length - 1]?.levelName}
                </p>
              </div>

              {isLoading === "level" ? (
                <div className="_loadingSpinner2">
                  <Spin />
                </div>
              ) : (
                <>
                  {currentLevel?.length ? (
                    <>
                      <div className="_levelContentBody">
                        {currentLevel?.map((module: ModuleType, i: number) => {
                          const rgbToRgba = (rgb: any, opacity: any) => {
                            const [r, g, b] = rgb.match(/\d+/g);
                            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
                          };
                          return (
                            <div key={i} className="_hCard">
                              <div
                                className="_hCardHeader"
                                style={{
                                  backgroundColor: module?.color,
                                }}
                              >
                                <p
                                  style={{
                                    color: isDarkColor(module?.color)
                                      ? "white"
                                      : "black",
                                  }}
                                >
                                  {firstLetterCap(module?.moduleName)}
                                </p>
                              </div>
                              <div
                                // onMouseUp={handleMouseUp}
                                className="_hCardContent"
                                style={{
                                  backgroundColor: rgbToRgba(module.color, 0.4),
                                  borderRight: `2px solid ${rgbToRgba(
                                    module.color,
                                    0.4
                                  )}`,
                                }}
                              >
                                {module?.lessons?.map(
                                  (lesson: LessonType, i: number) => {
                                    return (
                                      <div key={i} className="_vCard">
                                        <Tooltip
                                          placement="top"
                                          title={
                                            <>
                                              {module?.moduleName} <br />
                                              {lesson?.lessonName}
                                            </>
                                          }
                                        >
                                          <div
                                            className="_vCardHeader"
                                            onClick={() => {
                                              handleTargetClick(
                                                lesson?.lessonId,
                                                activeLevel,
                                                lesson?.moduleId,
                                                currentLevel
                                              );
                                            }}
                                          >
                                            <p>
                                              {lesson?.lessonName?.length > 35
                                                ? `${lesson?.lessonName?.slice(
                                                    0,
                                                    35
                                                  )}...`
                                                : lesson?.lessonName}
                                            </p>
                                          </div>
                                        </Tooltip>
                                        {studentsByClassSubject?.map(
                                          (v: any, i: number) => {
                                            const _studentForUpdate =
                                              updateStatus?.find(
                                                (x) =>
                                                  x?.lessonId ===
                                                    lesson?.lessonId &&
                                                  x?.studentId ===
                                                    v?.studentId &&
                                                  x?.levelId ===
                                                    module?.levelId &&
                                                  x?.moduleId ===
                                                    module?.moduleId
                                              );

                                            const _student =
                                              lesson?.progress?.find(
                                                (x) =>
                                                  v?.studentId == x?.studentId
                                              );
                                            const _obj: any = {
                                              lessonId: lesson?.lessonId,
                                              studentId: v?.studentId,
                                              levelId: module?.levelId,
                                              moduleId: module?.moduleId,
                                              subjectId:
                                                activeSubject?.subjectId,
                                              classId: classId,
                                            };

                                            const isPassButton =
                                              _studentForUpdate?.status ===
                                                "pass" ||
                                              _student?.status === "pass";
                                            const isFailButton =
                                              _studentForUpdate?.status ===
                                                "fail" ||
                                              _student?.status === "fail";
                                            const isInProgressButton =
                                              _studentForUpdate?.status ===
                                                "in-progress" ||
                                              _student?.status ===
                                                "in-progress";

                                            const currentStatus: any =
                                              _studentForUpdate?.status ||
                                              _student?.status;
                                            const orders = [
                                              "in-progress",
                                              "pass",
                                              "fail",
                                              "initial",
                                            ];

                                            const nextStatus = (() => {
                                              const currentIndex =
                                                orders.indexOf(currentStatus);
                                              return currentIndex !== -1
                                                ? orders[
                                                    (currentIndex + 1) %
                                                      orders.length
                                                  ]
                                                : orders[0];
                                            })();

                                            return (
                                              <div
                                                key={i}
                                                // onDoubleClick={() =>
                                                //   handleDoubleClick(
                                                //     currentStatus
                                                //   )
                                                // } // Enable drag on double-click
                                                // onMouseDown={() =>
                                                //   handleMouseDown(_obj)
                                                // } // Start dragging
                                                // onMouseEnter={() =>
                                                //   handleMouseEnter(_obj)
                                                // }
                                                className="_studentColorBox"
                                                style={{
                                                  backgroundColor: getColor(
                                                    _studentForUpdate?.status ||
                                                      _student?.status
                                                  ),
                                                }}
                                                onClick={() => {
                                                  _handleStatusUpdate({
                                                    ..._obj,
                                                    progressId:
                                                      _student?.progressId
                                                        ? _student?.progressId
                                                        : null,
                                                    status: nextStatus,
                                                  });
                                                }}
                                                onContextMenu={(e) => {
                                                  e.preventDefault();
                                                  _student?.progressId &&
                                                    handleOpenNoteModal({
                                                      value: v,
                                                      studentId: v?.studentId,
                                                      studentName: `${v?.firstName} ${v?.lastName}`,
                                                      imageUrl: v?.imageUrl,

                                                      className: v?.className,

                                                      levelId: module?.levelId,

                                                      subjectId: v?.subjectId,
                                                      subjectName:
                                                        subjects?.find(
                                                          (x) =>
                                                            x?.subjectId ===
                                                            v?.subjectId
                                                        )?.subjectName,

                                                      lessonId:
                                                        lesson?.lessonId,
                                                      lessonName:
                                                        lesson?.lessonName,

                                                      progress:
                                                        _studentForUpdate ||
                                                        _student ||
                                                        module?.moduleId,
                                                    });
                                                }}
                                              >
                                                {_student?.notes && (
                                                  <div
                                                    className="_cornerNoteSign"
                                                    style={{
                                                      borderTop: `10px solid ${
                                                        isPassButton
                                                          ? "rgba(8, 93, 58, 1)"
                                                          : isInProgressButton
                                                          ? "rgba(181, 71, 8, 1)"
                                                          : "transparent"
                                                      }`,
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="_noDataFound">
                      <p>No data found!</p>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="_levelContainer">
              {levels
                ?.slice(selectedLevelIndex, levels?.length)
                ?.map((v: any, i: any) => (
                  <div
                    key={i}
                    className="_levelBox"
                    style={{
                      backgroundColor: `${v?.color}`,
                      cursor: isLoading ? "not-allowed" : "pointer",
                    }}
                    onClick={() => !isLoading && handleChangeLevel(v.levelId)}
                  >
                    <span
                      style={{
                        color: isDarkColor(v?.color) ? "white" : "black",
                      }}
                    >
                      {v?.levelName}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </section>
      ) : (
        <div className="_emptyText">
          <p>No subject found!</p>
        </div>
      )}
      <ProgressNoteModal
        open={noteModal}
        close={handleCancelNoteModal}
        data={noteModalData}
        onProgressAdded={handleProgressAdded}
      />
    </div>
  );
};

export default DesktopView;
