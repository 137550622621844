import React, { useEffect, useState } from "react";
import "./style.css";
import { GoDotFill } from "react-icons/go";
import { Input } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setUpdatedArray } from "../../../store/slices/teacherExamSlice";

const { TextArea } = Input;

interface Props {
  tableData?: any;
  customize?: boolean;
  allData?: any;
  grades?: any;
}

const CustomTable: React.FC<Props> = ({ tableData, customize, grades }) => {
  const { examDetail, currentStudent, updatedArray } = useAppSelector(
    (state) => state.teacherExam
  );
  const { user } = useAppSelector((state) => state.auth);
  const [detailData, setDetailData] = useState([]);
  const [data, setData] = useState([]);
  const [std, setStd] = useState({});
  const [grd, setGrd] = useState([
    { gradeId: 1, grade: "A+", score: 20 },
    { gradeId: 2, grade: "A", score: 15 },
    { gradeId: 3, grade: "B", score: 10 },
    { gradeId: 4, grade: "C", score: 5 },
    { gradeId: 5, grade: "D", score: 0 },
  ]);
  const [marks, setMarks] = useState<any>({
    marks: 0,
    totalMarks: 0,
  });
  const [percentage, setPercentage] = useState<number>(0);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   const _data = examDetail?.results?.filter(
  //     (v: any) => v?.studentId.toString() === currentStudent?.id.toString()
  //   );
  //   console.log("_data",_data);
  //   return;
  //   setDetailData(_data);
  // }, [examDetail,currentStudent]);

  useEffect(() => {
    tableData && modifyData(tableData);
  }, [tableData, examDetail, currentStudent]);

  const modifyData = (obj: any) => {
    if (obj && obj?.modules?.length > 0) {
      const _data: any = [];
      let subjectRowSpan = 0;
      let totalMarks = 0;
      let marks = 0;
      let percentage = 0;

      const gradeObj: any = {};

      if (examDetail) {
        examDetail?.grads?.forEach((grade: any) => {
          gradeObj[`grade${grade.grade.replace("+", "Plus")}`] = grade.score;
        });
      } else {
        grd?.forEach((grade: any) => {
          gradeObj[`grade${grade.grade.replace("+", "Plus")}`] = grade.score;
        });
      }

      obj?.modules?.forEach((module: any, moduleIndex: number) => {
        subjectRowSpan += module?.lessons?.length;
        module?.lessons?.forEach(
          (lesson: any, lessonIndex: number, lessonArr: any) => {
            const _found = examDetail?.results?.find(
              (v: any) =>
                (v?.studentId === currentStudent?.id ||
                  v?.studentId === user?.id) &&
                v?.subjectId === obj?.subjectId &&
                v?.moduleId === module?.moduleId &&
                v?.lessonId === lesson?.lessonId
            );
            const _ids = {
              studentId: currentStudent?.id || user?.id,
              subjectId: obj?.subjectId,
              moduleId: module?.moduleId,
              lessonId: lesson?.lessonId,
              examId: obj?.examId,
              levelId: module?.levelId,
            };

            const _obj = {
              lessonName: lesson?.lessonName,
              assessedOn: _found?.assessedOn || "",
              notes: _found?.notes || "",
              score: _found?.score
                ? _found?.score
                : _found?.score === 0
                ? 0
                : null,
              ids: _ids,
              ...gradeObj,
            };
            let totalLessons = 0;
            marks += _found?.score || 0;
            examDetail?.subjects?.forEach((subject: any) => {
              //@ts-ignore
              subject.modules.forEach((module) => {
                totalLessons += module.lessons.length;
              });
            });
            totalMarks = totalLessons * 20;

            if (moduleIndex === 0 && lessonIndex === 0) {
              _obj.moduleName = module?.moduleName;
              _obj.subjectName = obj?.subjectName;
            }
            if (lessonIndex === 0) {
              _obj.lessonRowSpan = lessonArr?.length;
              _obj.moduleName = module?.moduleName;
            }
            if (obj?.subjectName && module?.moduleName) {
              _data.push(_obj);
            } else {
              return;
            }
          }
        );
      });

      const newData: any = [..._data];
      newData[0].subjectRowSpan = subjectRowSpan;
      setData(newData);
      setMarks({ marks, totalMarks });
      setPercentage(percentage);
    }
  };

  const handleUpdateResult = (
    key: string,
    value: string | number,
    ids: any
  ) => {
    const _temp: any = [...updatedArray];

    const currentEntry = data.find(
      (item: any) =>
        item?.ids?.studentId === ids?.studentId &&
        item?.ids?.subjectId === ids?.subjectId &&
        item?.ids?.moduleId === ids?.moduleId &&
        item?.ids?.lessonId === ids?.lessonId &&
        item?.ids?.examId === ids?.examId &&
        item?.ids?.levelId === ids?.levelId
    );

    const _foundIndex: number = _temp.findIndex(
      (v: any) =>
        v?.studentId === ids?.studentId &&
        v?.subjectId === ids?.subjectId &&
        v?.moduleId === ids?.moduleId &&
        v?.lessonId === ids?.lessonId &&
        v?.examId === ids?.examId &&
        v?.levelId === ids?.levelId
    );

    if (_foundIndex !== -1) {
      const updatedItem = { ..._temp[_foundIndex], [key]: value };
      _temp[_foundIndex] = updatedItem;
    } else {
      const newEntry = {
        ...ids,
        //@ts-ignore
        ...currentEntry,
        [key]: value,
      };
      _temp.push(newEntry);
    }

    dispatch(setUpdatedArray(_temp));
  };

  const handleEmpty = () => {};

  return (
    <>
      <div className="_customTableWrapper">
        <table width={"100%"} className="_customTable">
          <thead>
            <tr>
              <th>Subject</th>
              <th>Module</th>
              <th>Lesson</th>
              <th>Assessed on</th>
              <th>Notes</th>
              <th>Grade A+</th>
              <th>Grade A</th>
              <th>Grade B</th>
              <th>Grade C</th>
              <th>Grade D</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((v: any, i: number) => {
              const _isUpdate: any = updatedArray?.find(
                (x: any) =>
                  v?.ids?.studentId === x?.studentId &&
                  v?.ids?.subjectId === x?.subjectId &&
                  v?.ids?.moduleId === x?.moduleId &&
                  v?.ids?.lessonId === x?.lessonId &&
                  v?.ids?.examId === x?.examId &&
                  v?.ids?.levelId === x?.levelId
              );
              if (_isUpdate) {
                delete v?.score;
              }
              return (
                <tr key={i}>
                  {v?.subjectName && (
                    <td
                      className="_subjectNameTd"
                      style={{ minWidth: "120px" }}
                      rowSpan={v?.subjectRowSpan}
                    >
                      {v?.subjectName}
                    </td>
                  )}
                  {v?.moduleName && (
                    <td rowSpan={v?.lessonRowSpan}>{v?.moduleName}</td>
                  )}
                  <td>{v?.lessonName}</td>

                  <td>
                    {customize && user?.role === "teacher" ? (
                      <TextArea
                        style={{ width: "200px" }}
                        // className={user?.role === 'teacher'?"":"disable_text"}
                        value={
                          user?.role === "teacher"
                            ? _isUpdate?.assessedOn || v?.assessedOn
                            : v?.assessedOn || "---"
                        }
                        onChange={(e) => {
                          handleUpdateResult(
                            "assessedOn",
                            e.target.value,
                            v?.ids
                          );
                        }}
                      />
                    ) : (
                      v?.assessedOn || "---"
                    )}
                  </td>
                  <td>
                    {customize && user?.role === "teacher" ? (
                      <TextArea
                        style={{ width: "200px" }}
                        // className={user?.role === 'teacher'?"":"disable_text"}
                        value={
                          user?.role === "teacher"
                            ? _isUpdate?.notes || v?.notes
                            : v?.notes || "---"
                        }
                        onChange={(e) => {
                          handleUpdateResult("notes", e.target.value, v?.ids);
                        }}
                      />
                    ) : (
                      v?.notes || "---"
                    )}
                  </td>
                  <td className="_gradeTd">
                    <div
                      className={
                        _isUpdate?.score === v?.gradeAPlus ||
                        v?.score === v?.gradeAPlus
                          ? "_match"
                          : "_NotMatch"
                      }
                      onClick={(e) => {
                        if (user?.role === "teacher") {
                          customize
                            ? handleUpdateResult("score", 20, v?.ids)
                            : handleEmpty();
                        }
                      }}
                    >
                      {v?.gradeAPlus || "-"}
                    </div>
                  </td>
                  <td className="_gradeTd">
                    <div
                      className={
                        _isUpdate?.score === v?.gradeA || v?.score === v?.gradeA
                          ? "_match"
                          : "_NotMatch"
                      }
                      onClick={(e) => {
                        if (user?.role === "teacher") {
                          customize
                            ? handleUpdateResult("score", 15, v?.ids)
                            : handleEmpty();
                        }
                      }}
                    >
                      {v?.gradeA || "-"}
                    </div>
                  </td>
                  <td className="_gradeTd">
                    <div
                      className={
                        _isUpdate?.score === v?.gradeB || v?.score === v?.gradeB
                          ? "_match"
                          : "_NotMatch"
                      }
                      onClick={(e) => {
                        if (user?.role === "teacher") {
                          customize
                            ? handleUpdateResult("score", 10, v?.ids)
                            : handleEmpty();
                        }
                      }}
                    >
                      {v?.gradeB || "-"}
                    </div>
                  </td>
                  <td className="_gradeTd">
                    <div
                      className={
                        _isUpdate?.score === v?.gradeC || v?.score === v?.gradeC
                          ? "_match"
                          : "_NotMatch"
                      }
                      onClick={(e) => {
                        if (user?.role === "teacher") {
                          customize
                            ? handleUpdateResult("score", 5, v?.ids)
                            : handleEmpty();
                        }
                      }}
                    >
                      {v?.gradeC || "-"}
                    </div>
                  </td>
                  <td className="_gradeTd">
                    <div
                      className={
                        _isUpdate?.score === v?.gradeD || v?.score === v?.gradeD
                          ? "_match"
                          : "_NotMatch"
                      }
                      onClick={(e) => {
                        if (user?.role === "teacher") {
                          customize
                            ? handleUpdateResult("score", 0, v?.ids)
                            : handleEmpty();
                        }
                      }}
                    >
                      {v?.gradeD === 0 ? v?.gradeD : "-"}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <h2 style={{ marginBlock: "20px", fontSize: "14px", fontWeight: 600 }}>
        Total Marks {`${marks?.marks}`}/{`${marks?.totalMarks}`}{" "}
        <GoDotFill color="var(--primary-color)" /> Percentage:
        {isNaN((marks.marks / marks.totalMarks) * 100)
          ? "0"
          : `${((marks.marks / marks.totalMarks) * 100).toFixed(2)}`}
        %
      </h2>
    </>
  );
};

export default CustomTable;
