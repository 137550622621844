import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Select, notification } from "antd";
import "../../../styles/components/modals/studentModal.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import SearchableDropdown from "../../SearchableDropdown";
import { assignStudents } from "../../../store/services/student";
import { getAllStudents } from "../../../store/services/student";
import { getStudentSubjects } from "../../../store/services/class";

interface CreateNotificationsProps {
  isOpen: boolean;
  singleStudentId?: any;
  setIsOpen: (isOpen: boolean) => void;
  page: number;
  perPage: number;
  fetchAllStudents: () => void;
  studentData?: any;
}

interface Subject {
  subjectClassId: number;
  subjectId: number;
  classId: number;
  subjectName: string;
}

interface ClassItem {
  classId: number;
  className: string;
  students: number;
  teachers: number | null;
  subjects: Subject[];
}

const StudentModal: React.FC<CreateNotificationsProps> = ({
  isOpen,
  setIsOpen,
  singleStudentId,
  page,
  perPage,
  fetchAllStudents,
  studentData,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [fetchValue, setFetchValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classId, setClassId] = useState("");
  const [classDataId, setClassDataId] = useState("");
  const [subjectIds, setSubjectIds] = useState([]);
  const [isClassSubjectsFetched, setIsClassSubjectsFetched] =
    useState<boolean>(false);
  // const getAllClassSubjects = async () => {
  //   try {
  //     setIsClassSubjectsFetched(true);
  //     const res: any = await getStudentSubjects({studentId:singleStudentId});
  //     setData(res.subjects);
  //     setIsClassSubjectsFetched(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const setClassAndSelectedIds = async () => {
      if (isOpen && studentData) {
        setIsClassSubjectsFetched(true);
        setClassDataId(studentData.class);
        if (Array.isArray(studentData.subjects)) {
          const selectedSubjectIds = studentData.subjects.map(
            (subject: any) => subject.subjectId
          );
          setSubjectIds(selectedSubjectIds);
        }
        setData(studentData.subjects);
        setIsClassSubjectsFetched(false);
        // await getAllClassSubjects();
      }
    };
    setClassAndSelectedIds();
  }, [studentData, isOpen]);

  const handleCancel = () => {
    setIsOpen(false);
    form.resetFields();
    setClassId("");
    setClassDataId("");
    setSubjectIds([]);
    setData([]);
  };

  const onChange = (e: any) => {
    setClassDataId(e);
    const findClass: any = fetchValue.find((item: ClassItem) => {
      return e === item.classId;
    });
    setSubjectIds([]);
    setClassId(findClass.classId);
    if (findClass && Array.isArray(findClass.subjects)) {
      setData(findClass.subjects);
    }
  };

  const sendData = async () => {
    setLoading(true);
    try {
      const mianData = {
        classId: classId || studentData?.classId,
        studentId: String(singleStudentId),
        subjectIds: subjectIds,
        levelId: 0,
      };
      if (data.length === 0) {
        notification.error({ message: "Subject is empty" });
        return;
      }
      if (subjectIds?.length <= 0) {
        notification.error({ message: "Please select subject!" });
        return;
      }
      if (!mianData.classId || !mianData.studentId || !mianData.subjectIds) {
        notification.error({ message: "Empty fields are not allowed" });
        return;
      }
      const res = await assignStudents(mianData);

      if (res) {
        await fetchAllStudents();
        handleCancel();
        setIsOpen(false);
        setLoading(false);
        setClassId("");
        setSubjectIds([]);
        setData([]);
      }
    } catch (error: any) {
      console.error("error student modal", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={<div className="modal_title">Assign class/Subjects</div>}
      open={isOpen}
      className="modal"
      centered
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} className="form" layout="vertical">
        <SearchableDropdown
          onChange={onChange}
          setFetchedOptions={setFetchValue}
          url="/class/get-all"
          mode={false}
          columns={["className"]}
          defaultValue={classDataId}
          placeholder="Search by class name"
          params={{ status: "active" }}
        />
        {data?.length > 0 && (
          <Select
            className="select"
            placeholder="Select Subjects"
            onChange={(e) => {
              setSubjectIds(e);
            }}
            mode="multiple"
            value={subjectIds}
            style={{ width: 120 }}
            options={data.map((item: any) => ({
              value: item.subjectId,
              label: item.subjectName,
            }))}
          />
        )}
      </Form>
      <div className="parent-btn">
        <Button
          onClick={handleCancel}
          disabled={loading}
          className="cancel-btn btn"
        >
          Cancel
        </Button>
        <Button
          onClick={sendData}
          loading={loading}
          disabled={loading || isClassSubjectsFetched}
          className="assign-btn btn"
        >
          Assign
        </Button>
      </div>
    </Modal>
  );
};

export default StudentModal;
