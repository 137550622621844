import "antd/dist/reset.css";
import "../styles/style.css";
import Routes from "./Routes";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useAppSelector } from "../store/hooks";
import { getImage } from "../store/services/settings";
import "aos/dist/aos.css";
import { socket } from "./socket";
import { NotificationType } from "../store/interfaces/notifications/Notifications";
import { getSingleUser } from "../store/services/adminLogin";
import hatt from "../assets/acedemic-hat.png";
import { getNotificationsCount } from "../store/services/notificationCounts";
import { getAllThemes } from "../store/services/theme";
import store from "../store";
import {
  setMessages,
  setSingleMessage,
} from "../store/slices/support/suportSlice";
import { getUnreadMessages, markReadMessage } from "../store/services/supports";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { MdOutlineZoomInMap } from "react-icons/md";

const App: React.FC = () => {
  const userId = useAppSelector((state) => state.auth.user?.id);
  const { themeState } = useAppSelector((state) => state.theme);
  const { favicon } = useAppSelector((state) => state.settings);
  const isAuthenticated = localStorage.getItem("token");
  const [online, setOnline] = useState(navigator.onLine);
  const activeUserChat = useAppSelector(
    (state) => state.support.activeUserChat
  );
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Wait for activeUserChat to be available before proceeding
  const isActiveUserChatAvailable =
    activeUserChat !== null && activeUserChat !== undefined;

  useEffect(() => {
    if (isAuthenticated && online && !userId) {
      getSingleUser();
    }
  }, [isAuthenticated, online, userId]);

  const changeFavicon = (src: string) => {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = src;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  useEffect(() => {
    if (favicon) {
      changeFavicon(favicon);
    }
  }, [favicon]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setOnline(navigator.onLine);
      if (!navigator.onLine) {
        notification.error({ message: "Check your internet connection" });
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && online) {
      getImage();
    }
  }, [isAuthenticated, online]);

  useEffect(() => {
    if (isAuthenticated && userId && online) {
      socket.connect();
      if (userId) {
        socket.emit("join", userId);
      }
      socket.on("subscribe", () => {
        if (userId) {
          socket.emit("join", userId);
        }
      });

      const handleNotification = async (data: NotificationType) => {
        if (data?.type === "message") {
          if (
            isActiveUserChatAvailable &&
            activeUserChat?.id &&
            data?.obj?.length > 0 &&
            activeUserChat?.id === data?.obj[0]?.chat_room_id
          ) {
            const obj = data.obj.length > 0 ? data.obj[0] : null;
            if (obj) {
              const allMessage = [
                ...store?.getState()?.support?.messages,
                ...data.obj,
              ];
              //@ts-ignore
              store.dispatch(setSingleMessage(obj));
              store.dispatch(setMessages(allMessage));
            }
            await getNotificationsCount();
            await markReadMessage(activeUserChat?.id);
          }
          await getUnreadMessages();
        }

        if ("Notification" in window && data?.message && data?.show) {
          if (Notification.permission === "granted") {
            new Notification("New Notification", {
              body: data?.message,
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                new Notification("New Notification", {
                  body: data?.message,
                });
              }
            });
          }
        }
      };

      socket.on("notification", handleNotification);

      return () => {
        socket.off("notification", handleNotification);
        socket.disconnect();
      };
    }
  }, [isAuthenticated, userId, online, isActiveUserChatAvailable]);

  const getTheme = async () => {
    const response = await getAllThemes();
    if (response) {
      const activeTheme = response?.themes?.filter(
        (item: any) => item?.active === 1
      )?.[0];

      // @ts-ignore
      Object.entries(activeTheme).forEach(([key, value]: [string, string]) => {
        document.body.style.setProperty(`--${key}`, value);
      });
    }
  };

  useEffect(() => {
    getTheme();
  }, [themeState]);

  const handleFullScreenToggle = () => {
    const element = document.documentElement as HTMLElement & {
      webkitRequestFullscreen?: () => Promise<void>;
      msRequestFullscreen?: () => Promise<void>;
    };

    const exit = document as Document & {
      webkitExitFullscreen?: () => Promise<void>;
      msExitFullscreen?: () => Promise<void>;
    };

    if (!document.fullscreenElement) {
      // Enter full screen
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
      // Exit full screen
      if (exit.exitFullscreen) {
        exit.exitFullscreen();
      } else if (exit.webkitExitFullscreen) {
        exit.webkitExitFullscreen();
      } else if (exit.msExitFullscreen) {
        exit.msExitFullscreen();
      }
    }
  };

  return (
    <>
      <div onClickCapture={handleFullScreenToggle} className="zoomBtnBox">
        {isFullScreen ? (
          <MdOutlineZoomInMap size={20} />
        ) : (
          <MdOutlineZoomOutMap size={20} />
        )}
      </div>
      {online ? (
        isAuthenticated && !userId ? (
          <div
            style={{
              height: "100vh",
              padding: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={hatt} className="heartbeat home-loading" alt="logo" />
          </div>
        ) : (
          <Routes />
        )
      ) : null}
      {!online && (
        <div className="lost_connection">
          <p>There is no internet connection!</p>
        </div>
      )}
    </>
  );
};

export default App;
