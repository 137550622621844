// import React from 'react'
// import { useNavigate } from 'react-router-dom'

// import { FaEye } from "react-icons/fa";

// import { useAppSelector } from '../../store/hooks';
// import moment from 'moment';

// const CardReport = ({data}:any) => {

//     const navigate = useNavigate();
//     const { user } = useAppSelector(state => state.auth);

//     return (
//       <div className="card_report_header_parent">
//         <div className="card_report_header">
//           <div className="card_report_header_h1">
//             {data?.title} <span className="report_dots"/>
//             <span>{moment(data?.createdDate)?.format("MMM DD, YYYY")}</span>
//           </div>
//           <div className="card_report_header_p">report</div>
//         </div>
//         <FaEye
//           className="report_eye"
//           cursor={"pointer"}
//           size={20}
//           onClick={() => {
//             navigate("/student/report");
//           }}
//         />
//         <div className="btn_report_head">
//           <div
//             className="report_card_btn"
//             onClick={() => {
//               navigate("/student/report",{state: {reportId: data?.reportId}});
//             }}
//           >
//             View
//           </div>
//           {/* {user?.role === "teacher" && (
//             <div className="report_card_btn">Edit</div>
//           )} */}
//         </div>
//       </div>
//     );
// }

// export default CardReport

import React from "react";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import moment from "moment";

const CardReport = ({ data }: any) => {
  const navigate = useNavigate();

  const truncateTitle = (title: string) => {
    return title.length > 8 ? `${title.slice(0, 8)}...` : title;
  };

  return (
    <div className="card_report_header_parent">
      <div className="card_report_header">
        <div className="card_report_header_h1" title={data?.title}>
          <span className="report_student_performence">
            {/* {truncateTitle(data?.title)} */}
            {data?.title}
          </span>{" "}
          <span className="report_dots" />
          <span>{moment(data?.createdDate)?.format("MMM DD, YYYY")}</span>
        </div>
        <p>Generated for: {`${data?.firstName || "N/A"} ${data?.lastName}`}</p>
        <div className="card_report_header_p">report</div>
      </div>
      <FaEye
        className="report_eye"
        cursor={"pointer"}
        size={20}
        onClick={() => {
          navigate("/student/report");
        }}
      />
      <div className="btn_report_head">
        <div
          className="report_card_btn"
          onClick={() => {
            navigate("/student/report", {
              state: { reportId: data?.reportId },
            });
          }}
        >
          View
        </div>
        {/* {user?.role === "teacher" && (
          <div className="report_card_btn">Edit</div>
        )} */}
      </div>
    </div>
  );
};

export default CardReport;
