import React from "react";

const AvatarRoundIcons = () => {
  const styles: any = {
    container: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 999,
    },
    wrapper: {
      width: "100%",
      height: "100%",
      position: "relative",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    leftBottomIcon: { position: "absolute", left: 0, top: 52 },
    leftTopIcon: { position: "absolute", left: 8 },
    rightTopIcon: { position: "absolute", right: 14, top: 0 },
    rightBottomIcon: { position: "absolute", right: 2, top: 45 },
  };
  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <svg
          width="13"
          height="68"
          viewBox="0 0 13 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={styles.leftBottomIcon}
        >
          <path
            d="M7.8193 67.9004C3.11239 57.34 0.622745 45.926 0.504425 34.3647C0.386105 22.8035 2.64163 11.3409 7.13142 0.686363L12.0439 2.75647C7.83734 12.7388 5.72411 23.4783 5.83496 34.3102C5.94582 45.1421 8.2784 55.836 12.6884 65.7302L7.8193 67.9004Z"
            fill={"var(--secondary-color)"}
            fillOpacity="0.3"
          />
        </svg>
        <svg
          width="73"
          height="49"
          viewBox="0 0 73 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={styles.leftTopIcon}
        >
          <path
            d="M0.615516 46.3237C7.39568 32.9353 17.6191 21.5942 30.2348 13.4662C42.8506 5.33824 57.4033 0.71675 72.397 0.0768497L72.6243 5.40282C58.5765 6.00235 44.9418 10.3323 33.122 17.9475C21.3022 25.5627 11.7237 36.1884 5.37127 48.7321L0.615516 46.3237Z"
            fill={"var(--secondary-color)"}
            fillOpacity="0.5"
          />
        </svg>

        <svg
          width="72"
          height="44"
          viewBox="0 0 72 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={styles.rightTopIcon}
        >
          <path
            d="M1.03092 0.0244097C15.1943 0.364918 29.0447 4.26108 41.3084 11.3546C53.5721 18.4481 63.8554 28.5113 71.2127 40.6186L66.657 43.3869C59.7639 32.0434 50.1293 22.6151 38.6393 15.9691C27.1493 9.32309 14.1726 5.67272 0.902802 5.35369L1.03092 0.0244097Z"
            fill={"var(--secondary-color)"}
            fillOpacity="0.75"
          />
        </svg>
        <svg
          width="15"
          height="77"
          viewBox="0 0 15 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={styles.rightBottomIcon}
        >
          <path
            d="M5.13776 0.840374C11.1336 12.4937 14.3367 25.3819 14.4939 38.4862C14.6512 51.5906 11.7582 64.5519 6.0437 76.3457L1.24637 74.0212C6.60034 62.9715 9.31079 50.8278 9.16348 38.5502C9.01617 26.2725 6.01514 14.1974 0.397583 3.27928L5.13776 0.840374Z"
            fill={"var(--secondary-color)"}
          />
        </svg>
      </div>
    </div>
  );
};

export default AvatarRoundIcons;
