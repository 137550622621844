import React, { useState } from "react";
import CustomTabs from "../components/CustomTabs/CustomTabs";
import Overview from "../components/Reporting/Overview";
import TopPerformers from "../components/Reporting/TopPerformers";
import LeastPerformers from "../components/Reporting/LeastPerformer";
import StudentAboveTarget from "../components/Reporting/StudentAboveTarget";
import StudentBelowTarget from "../components/Reporting/StudentBelowTarget";
import GHeader from "../components/GeneralHeader/GHeader";
import NotAccess from "./NotAccess";
import { checkRoutePermissions } from "../utils/permission";
const Reporting = () => {
  const [activeItem, setActiveItem] = useState("Overview");
  const list = [
    "Overview",
    "Top performers",
    "Lowest performers",
    "Students above target",
    "Students below target",
  ];

  return checkRoutePermissions("reportingPage") ? (
    <div className="mainDiv responsive">
      <GHeader
        heading="Reporting"
        headingText="Review top and least performers , students above and below target."
      />
      <div className="settingMin">
        <CustomTabs
          list={list}
          setActiveItem={setActiveItem}
          activeItem={activeItem}
        />
        {/* <div className="reporting "> */}
        {activeItem === "Overview" ? (
          <Overview />
        ) : activeItem === "Top performers" ? (
          <TopPerformers />
        ) : activeItem === "Lowest performers" ? (
          <LeastPerformers />
        ) : activeItem === "Students above target" ? (
          <StudentAboveTarget />
        ) : activeItem === "Students below target" ? (
          <StudentBelowTarget />
        ) : (
          ""
        )}
        {/* </div> */}
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Reporting;
