import React, { useEffect, useState } from "react";
import "../../styles/pages/studentoverview.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { GoHome } from "react-icons/go";
import GHeader from "../../components/GeneralHeader/GHeader";
import TeacherCard from "../../components/Cards/TeacherCard";
import SubjectStudentLevel from "../../components/studentOverview/subjectStudentLevel";
import StudentBrief from "../../components/studentOverview/StudentBrief";
import Overview from "../../components/studentOverview/Overview";
import img from "../../assets/attachment.png";
import Reports from "../Reports";
import Exam from "../../components/studentOverview/Exam";
import { Button, Spin } from "antd";
import { getSingleStudent } from "../../store/services/student";

const ClassesStudent: React.FC = () => {
  const [activeItem, setActiveItem] = useState({
    subjectId: null,
    activeTab: "Overview",
  });
  const [studentData, setStudentData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { state } = useLocation();
  const navigate = useNavigate();

  const studentId = state?.id;

  if (!studentId) {
    navigate("/students");
    console.error("Missing student ID:");
  }

  useEffect(() => {
    getData();
  }, [state]);

  const getData = async () => {
    !loading && setLoading(true);
    const data = await getSingleStudent({ id: studentId });
    if (data) {
      setStudentData(data);
    }
    setLoading(false);
  };

  const list = studentData?.subjects && [
    {
      subjectId: null,
      subjectName: "Overview",
    },
    ...studentData?.subjects?.map((sub: any) => {
      return { subjectId: sub.subjectId, subjectName: sub.subjectName };
    }),
    {
      subjectId: null,
      subjectName: "Student Brief",
    },
    {
      subjectId: null,
      subjectName: "Exams",
    },
    {
      subjectId: null,
      subjectName: "Reports",
    },
  ];

  return (
    <>
      {!loading ? (
        <>
          {studentData ? (
            <div className="classesStd" style={{ backgroundColor: "#fff" }}>
              <div className="_breadcrumb">
                <GoHome
                  size={20}
                  onClick={() => navigate("/curriculum")}
                  style={{ cursor: "pointer" }}
                />{" "}
                <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
                <span onClick={() => navigate(-1)}>Students</span>{" "}
                <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
                {studentData && (
                  <>
                    <span style={{ cursor: "default" }}>
                      {studentData?.className} / {studentData?.fistName}{" "}
                      {studentData?.LastName}
                    </span>{" "}
                    <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
                  </>
                )}
                <p>Student Overview</p>
              </div>
              <GHeader
                heading="Student Overview"
                headingText="Review and manage the classes"
              />
              <div className="Custom_tabs_containers">
                {list?.map((tab: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        activeItem?.activeTab === tab.subjectName
                          ? "custom_active_tab"
                          : "custom_tab"
                      } custom-tab-all `}
                      onClick={() =>
                        setActiveItem({
                          subjectId: tab.subjectId,
                          activeTab: tab.subjectName,
                        })
                      }
                    >
                      {tab.subjectName}
                    </div>
                  );
                })}
              </div>
              {studentData && (
                <TeacherCard
                  style={{ paddingBlock: "10px" }}
                  data={{
                    firstName: studentData?.firstName,
                    LastName: studentData?.LastName,
                    class: studentData?.className,
                    imageUrl: studentData?.imageUrl
                      ? studentData?.imageUrl
                      : img,
                  }}
                />
              )}{" "}
              <br />
              {activeItem.activeTab === "Student Brief" ? (
                <>
                  <StudentBrief studentData={studentData} />
                </>
              ) : activeItem.activeTab === "Overview" ? (
                <>
                  <Overview studentData={studentData} />
                </>
              ) : activeItem.activeTab === "Exams" ? (
                <>
                  <Exam studentData={studentData} />
                </>
              ) : activeItem.activeTab === "Reports" ? (
                <>
                  <Reports studentData={studentData} />
                </>
              ) : (
                <SubjectStudentLevel
                  studentData={studentData}
                  activeSubjectId={activeItem?.subjectId}
                />
              )}
            </div>
          ) : (
            <div className="_loadingBox">
              <p>No data found!</p>
              <Button onClick={() => navigate(-1)}>Go Back</Button>
            </div>
          )}
        </>
      ) : (
        <div className="_loadingBox">
          <Spin />
        </div>
      )}
    </>
  );
};

export default ClassesStudent;
