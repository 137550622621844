import React, { useEffect, useState, useRef } from "react";
import { checkRoutePermissions } from "../../utils/permission";
import "../../styles/pages/support.css";
import avatar from "../../assets/moulviAvatar.jpeg";
import NotAccess from "../NotAccess";
import { IoIosSend } from "react-icons/io";
import { useAppSelector } from "../../store/hooks";
import {
  createChatRoom,
  getallMessage,
  getAllRooms,
  getUnreadMessages,
  markReadMessage,
  sendMessage,
} from "../../store/services/supports";
import { Spin } from "antd";
import Loading from "../../components/Loading/Loading";
import store from "../../store";
import {
  setActiveUserChat,
  setMessages,
} from "../../store/slices/support/suportSlice";
import { CgAttachment } from "react-icons/cg";
import FileUploadModal from "../../components/Modals/supports/FileUploadModal";
import { socket } from "../../config/socket";
import SearchableDropdown from "../../components/SearchableDropdown";
import { FaRocketchat } from "react-icons/fa";

const TeacherSupport = () => {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [loadedMessage, setLoadedMessage] = useState(true);
  const [load, setLoad] = useState(false);
  const [chatId, setChatId] = useState<any>(null);
  const [loadFirstTime, setLoadFirstTime] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [fetchValue, setFetchValue] = useState([]);
  const {
    loading,
    unReadmesg,
    messageloading,
    chatrooms,
    singlMessage,
    messages,
    activeUserChat,
  } = useAppSelector((state) => state.support);

  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (chatrooms && chatrooms.length) {
      setChatId(chatrooms[0].id);
      store.dispatch(setActiveUserChat(chatrooms[0]));
    }
  }, [chatrooms]);

  const GetMessage = async (chatroomId: any) => {
    const res = await getallMessage(chatroomId, page);
    if (res.data.length === 0) {
      setLoadedMessage(false);
    }
    if (res) {
      setLoadFirstTime(true);
      await markReadMessage(chatroomId);
      await getUnreadMessages();
    }
  };

  const sendMessagehandle = async (roomId: any) => {
    setLoad(true);
    if (!messageContent.trim()) {
      setLoad(false);
      return;
    }
    const payload = {
      sender: user?.id,
      content: messageContent,
    };

    try {
      setMessageContent("");
      const chatMessage = {
        sender_id: user?.id,
        content: messageContent,
        id: Math.random().toString(36).substr(2, 9) + Date.now(),
      };
      const allMessage = [...messages, chatMessage];

      store.dispatch(setMessages(allMessage));
      const res = await sendMessage(roomId, payload);
      if (res) {
        socket.emit("sendMessage", { roomId, payload });
        setLoad(false);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setLoad(false);
    } finally {
      setLoad(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const isVideo = (fileUrl: any) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = fileUrl.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  useEffect(() => {
    getAllRooms();
  }, []);

  useEffect(() => {
    if (activeUserChat && loadedMessage) {
      GetMessage(activeUserChat?.id);
    }
  }, [activeUserChat, page]);

  useEffect(() => {
    if (chatId) {
      GetMessage(chatId);
    }
  }, [chatId, page]);

  useEffect(() => {
    if (messageContainerRef?.current && loadFirstTime) {
      const chatContainer = messageContainerRef.current;

      const previousScrollHeight = chatContainer.scrollHeight;
      const previousScrollTop = chatContainer.scrollTop;

      const handleScrollAdjustment = () => {
        const newScrollHeight = chatContainer.scrollHeight;

        if (previousScrollTop < 400) {
          chatContainer.scrollTop = 400;
        } else {
          const scrollOffset = newScrollHeight - previousScrollHeight;
          chatContainer.scrollTop = previousScrollTop + scrollOffset;
        }
      };

      handleScrollAdjustment();
    }
  }, [messages]);

  useEffect(() => {
    if (messageContainerRef?.current) {
      const chatContainer = messageContainerRef?.current;
      const handleScroll = () => {
        if (chatContainer.scrollTop === 0) {
          const previousHeight = chatContainer.scrollHeight;

          setPage((prevPage) => prevPage + 1);

          setTimeout(() => {
            const newHeight = chatContainer.scrollHeight;
            chatContainer.scrollTop = newHeight - previousHeight;
          }, 300);
        }
      };

      chatContainer.addEventListener("scroll", handleScroll);

      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messageContainerRef, messages]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [loadFirstTime, singlMessage]);

  const setActiveChatUsr = async (item: any) => {
    store.dispatch(setActiveUserChat(item));
  };

  const onChange = async (e: any) => {
    const value: any = fetchValue.find((item: any) => {
      return e === item.firstName;
    });
    const res = await createChatRoom({
      userId: value.id,
    });
    if (res) {
      await getAllRooms();
    }
  };

  return checkRoutePermissions("support") ? (
    <div className="support_main">
      <div className="support_left">
        <div className="input-Search-support">
          <SearchableDropdown
            onChange={onChange}
            url="/admin/get-all"
            mode={false}
            columns={["firstName"]}
            setFetchedOptions={setFetchValue}
            type="firstName"
            placeholder="Search by name"
            params={{ status: "active", perPage: 10 }}
          />
        </div>
        <div className="support_left_child">
          <div className="child_wraper">
            {loading ? (
              <Loading />
            ) : (
              chatrooms.length > 0 &&
              chatrooms.map((item: any) => {
                return (
                  <div
                    key={item.id}
                    className={`profile_support ${
                      item.id === activeUserChat?.id
                        ? "support_user_active"
                        : ""
                    }`}
                    onClick={() => {
                      store.dispatch(setMessages([]));
                      setActiveChatUsr(item);
                      setPage(1);
                      setLoadedMessage(true);
                    }}
                  >
                    <img
                      src={item.imageUr ? item.imageUr : avatar}
                      className="image_support"
                      alt="profile icon"
                    />
                    <div className="chat_parent">
                      <p className="chat_name">
                        {item.firstName} {item.lastName}
                      </p>
                      {unReadmesg?.length > 0 &&
                        unReadmesg.map((message: any) => {
                          return (
                            message.chat_room_id === item.id &&
                            activeUserChat?.id !== item.id && (
                              <p className="chat_messages">{`${message?.unread} Unread message(s)`}</p>
                            )
                          );
                        })}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {activeUserChat ? (
        <div className="support_right">
          <div className="support_right_main">
            <div className="support_chat_profile">
              <img
                src={activeUserChat.imageUr ? activeUserChat.imageUr : avatar}
                alt="profile"
              />
              <p>{`${activeUserChat?.firstName} ${
                activeUserChat?.lastName ?? ""
              }`}</p>
            </div>
            <div className="support_messages teacher" ref={messageContainerRef}>
              {messageloading ? (
                <Loading large="true" />
              ) : (
                messages?.length > 0 &&
                messages?.map((item: any, index) => {
                  return (
                    <div key={index}>
                      {" "}
                      {/* Use a div to ensure proper wrapping and add a unique key */}
                      {user?.id == item?.sender_id ? (
                        <>
                          {item.content && (
                            <div className="message_parent message-right">
                              <div className="support_message_text_right">
                                <p>{item.content}</p>
                              </div>
                            </div>
                          )}
                          {item?.file && item?.file != "null" && (
                            <div
                              className="message_parent message-right"
                              style={{ marginTop: item?.content ? "8px" : "" }}
                            >
                              <div className="support_img_text_right support_img_teacher">
                                {isVideo(item.file) ? (
                                  <video width="300" height="240" controls>
                                    <source src={item.file} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <div
                                    className="image_hover"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      height: "auto",
                                      width: "50%",
                                      position: "relative",
                                    }}
                                  >
                                    <a
                                      className="img_download_btn"
                                      style={{
                                        display: "none",
                                        justifyContent: "flex-end",
                                      }}
                                      href={item.file}
                                      download={item.file}
                                    >
                                      Download
                                    </a>
                                    <img
                                      style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                      }}
                                      src={item.file}
                                      alt="message"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {item.content && (
                            <div className="message_parent">
                              <img
                                src={avatar}
                                className="message_profile"
                                alt="profile"
                                width="200"
                                height="180"
                              />
                              <div className="support_message_text">
                                <p>{item.content}</p>
                              </div>
                            </div>
                          )}
                          {item.file && item.file != "null" && (
                            <div
                              className="message_parent"
                              style={{ marginTop: item.content ? "8px" : "" }}
                            >
                              <img
                                src={avatar}
                                className="message_profile"
                                alt="profile"
                              />
                              <div className="support_img_text support_img support_t_left">
                                {isVideo(item.file) ? (
                                  <video width="300" height="240" controls>
                                    <source src={item.file} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <div
                                    className="image_hover"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      height: "auto",
                                      width: "50%",
                                      position: "relative",
                                    }}
                                  >
                                    <a
                                      style={{
                                        display: "none",
                                        justifyContent: "flex-start",
                                      }}
                                      className="img_download_btn"
                                      href={item.file}
                                      download={item.file}
                                    >
                                      Download
                                    </a>
                                    <img
                                      style={{
                                        width: "100%",
                                        maxWidth: "100%",
                                      }}
                                      src={item.file}
                                      alt="message"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })
              )}
            </div>
            <div className="send-message">
              <input
                className="message_input"
                placeholder="Write your message"
                type="text"
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
              />
              <CgAttachment onClick={showModal} />
              {load ? (
                <Spin />
              ) : (
                <IoIosSend
                  className="iosend"
                  onClick={() => sendMessagehandle(activeUserChat?.id)}
                />
              )}
            </div>
            <FileUploadModal
              isModalOpen={isModalOpen}
              load={load}
              setLoad={setLoad}
              setIsModalOpen={setIsModalOpen}
              Page={page}
            />
          </div>
        </div>
      ) : (
        <div className="message_user_to_select">
          <FaRocketchat className="chat_user" />
          <p>Select A User To Chat</p>
        </div>
      )}
    </div>
  ) : (
    <NotAccess />
  );
};

export default TeacherSupport;
