import React from "react";

// @ts-ignore
import AvatarImage from "../../assets/avatar_teacher.png";
import { useNavigate } from "react-router-dom";

const TeacherCard = ({ data, style }: any) => {
  const navigate = useNavigate();
  return (
    <div className="teacher_performance_card" style={style ? style : {}}>
      <div className="border_blue" />
      <div className="card_left">
        <img
          src={data.imageUrl ? data.imageUrl : AvatarImage}
          alt="profile"
          onClick={() => navigate("/teachers")}
          style={{ cursor: "pointer" }}
        />
        <div className="info_container">
          <p className="name additionalTeacherClass">
            {data?.firstName} {data?.LastName}
          </p>
          {data?.class && <p className="student_class">{data?.class}</p>}
        </div>
      </div>

      {/* <div className='class_tag'>{data?.className}</div> */}
    </div>
  );
};

export default TeacherCard;
