import { useState } from "react";
import { Button, Modal, Select } from "antd";
import searchIcon from "../../../assets/search-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setCurrentIndex,
  setSearchStudent,
} from "../../../store/slices/Classes/classSlice";
import { setCurrentStd } from "../../../store/slices/teacherExamSlice";

const SearchStudentModal = ({ type }: { type?: string }) => {
  const { searchStudent } = useAppSelector((state) => state.class);
  const [load, setLoad] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { studentsByClassSubject } = useAppSelector((state) => state.student);
  const studentdetail = useAppSelector((state) => state.teacherExam);
  const student = studentdetail?.examDetail?.students;

  const dispatch = useAppDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(setSearchStudent(null));
  };

  const onChange = (value: string) => {
    dispatch(setSearchStudent(value));
  };

  const onSearch = (value: string) => {
    // console.log("search:", value);
  };

  const handleSearchClick = () => {
    setLoad(true);
    const index = studentsByClassSubject?.findIndex(
      //@ts-ignore
      (v) => v?.id === searchStudent
    );
    dispatch(setCurrentStd(index));
    handleCancel();
    setLoad(false);
  };
  console.log("student", student);
  return (
    <>
      <Button className="btn_std_modal" onClick={showModal}>
        Change Student
      </Button>
      <Modal
        title=""
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        centered
        className="_searchStudentModal"
      >
        <div className="_body">
          <img src={searchIcon} alt={"Search"} />
          <h2>Search for a Student</h2>
          <p>Search for a student to change.</p>
          <Select
            className="_studentSelectBox"
            showSearch
            placeholder="Search..."
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            // options={studentsByClassSubject?.map((v) => {
            options={student
              ?.filter((v: any, index: number, self: any[]) => {
                return (
                  self.findIndex((item: any) => item.id === v.id) === index
                );
              })
              ?.map((v: any) => {
                return {
                  //@ts-ignore
                  value: v?.id,
                  label: `${v?.firstName} ${v?.lastName}`,
                };
              })}
            value={searchStudent}
            allowClear
            onClear={() => dispatch(setSearchStudent(null))}
          />

          <div className="_footerButtons">
            <Button
              disabled={!searchStudent || load}
              loading={load}
              className="btn_std_modal"
              onClick={() => handleSearchClick()}
            >
              Search
            </Button>
            <Button onClick={handleCancel} disabled={load}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchStudentModal;
