import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Radio,
  Space,
  Select,
  notification,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import {
  createAnnoucenment,
  getAllAnnouncements,
  usersRole,
} from "../../../store/services/announcement";
import { title } from "process";
import { Role } from "../../../store/interfaces/Annoucements/Announcement";
import { increaseIncreamentCount } from "../../../store/slices/Annoucements/Announcements.slice";
import store from "../../../store";
import { getAllNotificationsData } from "../../../store/services/notifications";
import SearchableDropdown from "../../SearchableDropdown";

interface CreateNotificationsProps {
  isModalOpen: boolean;
  currentPage: number;
  setIsModalOpen: (isOpen: boolean) => void;
}

const CreateNotifications: React.FC<CreateNotificationsProps> = ({
  isModalOpen,
  setIsModalOpen,
  currentPage,
}) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState("general");
  const [data, setData] = useState([]);
  const [specific, setSpecific] = useState(false);
  const [specificVal, setSpecificVal] = useState("");
  const [specificUserValue, setSpecificUserValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [role, setRole] = useState("");
  const [fetchValue, setFetchValue] = useState([]);
  const [mainVal, setMainVal] = useState([]);

  const sendData = (val: any) => {
    // console.log(val);
    setMainVal(val);
  };

  const handleUsersChange = (value: any) => {
    // console.log("Selected users:", value);
  };

  const onChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setValue(newValue);
    setSpecific(newValue === "specific");
  };

  const onChangeGroup = (e: RadioChangeEvent) => {
    const newGroupValue = e.target.value;
    setSpecificVal(newGroupValue);
    setSelected(newGroupValue === "selected users");
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();

      if (value === "general") {
        const generalPayload = {
          title: values?.title,
          notificationType: values?.notificationType,
          message: values?.message,
        };
        const res = await createAnnoucenment(generalPayload);
        if (res) {
          notification.success({
            message: "Announcement Created Successfully",
          });
          setIsModalOpen(false);
          await getAllAnnouncements({ perPage: 10, page: currentPage });
        }
      } else if (value === "specific" && selected === false) {
        const specificAllPayload = {
          title: values?.title,
          notificationType: values?.notificationType,
          role: values?.role,
          userType: values?.userGroup,
          message: values?.message,
        };

        const res = await createAnnoucenment(specificAllPayload);
        if (res) {
          notification.success({
            message: "Announcement Created Successfully",
          });
          setIsModalOpen(false);
          await getAllAnnouncements({ perPage: 10, page: currentPage });
        }
      } else if (value === "specific" && selected === true) {
        const specificSelectedPayload = {
          title: values?.title,
          notificationType: values?.notificationType,
          role: values?.role,
          userGroup: values?.userGroup,
          users: specificUserValue,
          message: values?.message,
        };
        const res = await createAnnoucenment(specificSelectedPayload);
        if (res) {
          notification.success({
            message: "Announcement Created Successfully",
          });
          setIsModalOpen(false);
          await getAllAnnouncements({ perPage: 10, page: currentPage });
        }
      }

      setIsModalOpen(false);
      form.resetFields();
      setValue("general");
      setSpecific(false);
      setSpecificVal("");
      setRole("");
      setSpecificUserValue([]);
      setSelected(false);
    } catch (error) {
      console.error("Validation failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setValue("general");
    setSpecific(false);
    setSpecificUserValue([]);
    setRole("");
    setSpecificVal("");
    setSelected(false);
  };

  const getSpecificUser = (e: any) => {
    setSpecificUserValue(e);
  };

  useEffect(() => {
    (async () => {
      const res = await usersRole();
      setData(res?.data?.records);
    })();
  }, []);

  return (
    <Modal
      title="Create Notifications"
      open={isModalOpen}
      centered
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={loading}
          disabled={loading}
          className="btn_notification_modal"
          onClick={handleOk}
        >
          Send
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Notification Title"
          rules={[{ required: true, message: "Title is required" }]}
        >
          <Input placeholder="Title (Required)" />
        </Form.Item>
        <Form.Item
          name="notificationType"
          label="Notification Type"
          rules={[
            { required: true, message: "Please select notification type" },
          ]}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Radio value="general">General</Radio>
            <Radio value="specific">Specific</Radio>
          </Radio.Group>
        </Form.Item>
        {specific && (
          <>
            <Form.Item
              name="role"
              label="User Role"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                onChange={(e) => {
                  setRole(e);
                }}
                placeholder="User Type"
                options={[
                  { value: "admin", label: "Admin" },
                  { value: "teacher", label: "Teacher" },
                  { value: "student", label: "Student" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="userGroup"
              label="Select User Group"
              rules={[{ required: true }]}
            >
              <Radio.Group onChange={onChangeGroup} value={specificVal}>
                <Radio value="all">All</Radio>
                <Radio value="selected users">Selected Users</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {selected && role && (
          <SearchableDropdown
            mode={true}
            setFetchedOptions={setFetchValue}
            onChange={getSpecificUser}
            defaultValue={specificUserValue}
            type="monitoring"
            url={`/user/get-users-by-role?role=${role}`}
            columns={["firstName", "lastName"]}
            placeholder="Search By Name"
          />
        )}
        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: "Message is required" }]}
        >
          <Input.TextArea
            placeholder="Notification Message (Required)"
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNotifications;
