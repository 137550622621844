import RecentAnnouncment from "../components/AnnouncmentcComponent/RecentAnnouncment";
import { checkRoutePermissions } from "../utils/permission";
import NotAccess from "./NotAccess";

const Announcment = () => {
  return checkRoutePermissions("announcementsPage") ? (
    <div className="mainDiv">
      <div className="Announcemnt" style={{ borderRadius: "10px" }}>
        <RecentAnnouncment />
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Announcment;
