"use client";

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  message,
  Row,
  Spin,
  Tag,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {
  CheckCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";

import { LuUploadCloud } from "react-icons/lu";

// @ts-ignore
import graphup from "../../assets/graphup.png";

// @ts-ignore
import graphdown from "../../assets/graphdown.png";
import {
  getAboveTargetData,
  getBelowTargetData,
  getOverPerformerData,
  getUnderPerformerData,
} from "../../store/services/report";
import { getAboveTarget } from "../../store/services/state";
import ReportCard from "./ReportCard";

const { Dragger } = Upload;

// const props: UploadProps = {
//   name: "file",
//   multiple: true,
//   action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };

const Overview = () => {
  const [overPerformerData, setOverPerformerData] = useState(false);
  const [underPerformerData, setUnderPerformerData] = useState(false);
  const [belowTagetData, setBelowTargetData] = useState(false);
  const [aboveTargetData, setAboveTargetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataFetchCount, setDataFetchCount] = useState(0);

  const incrementFetchCount = () => {
    setDataFetchCount((prevCount) => prevCount + 1);
  };

  const fetchOverPerformerData = async () => {
    try {
      const res = await getOverPerformerData();
      if (res?.status === 200) {
        setOverPerformerData(res?.data);
      }
      incrementFetchCount();
    } catch (error) {
      incrementFetchCount();
      console.log("error", error);
    }
  };

  const fetchUnderperformerData = async () => {
    try {
      const res = await getUnderPerformerData();
      if (res?.status === 200) {
        setUnderPerformerData(res?.data?.class);
      }
      incrementFetchCount();
    } catch (error) {
      incrementFetchCount();
      console.log("error", error);
    }
  };

  const fetchAboveTargetData = async () => {
    try {
      const res = await getAboveTargetData();
      if (res?.status === 200) {
        setAboveTargetData(res?.data?.class);
      }
      incrementFetchCount();
    } catch (error) {
      incrementFetchCount();
      console.log("error", error);
    }
  };

  const fetchBelowTargetData = async () => {
    try {
      const res = await getBelowTargetData();
      if (res?.status === 200) {
        setBelowTargetData(res?.data?.class);
      }
      incrementFetchCount();
    } catch (error) {
      incrementFetchCount();
      console.log("error", error);
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchOverPerformerData();
    fetchUnderperformerData();
    fetchAboveTargetData();
    fetchBelowTargetData();
  }, []);

  useEffect(() => {
    if (dataFetchCount === 4) {
      setLoading(false);
    }
  }, [dataFetchCount]);

  return (
    <div className="main" style={{ height: "auto" }}>
      <Row gutter={[20, 20]}>
        {/* Card 1 */}
        <ReportCard
          type="success"
          data={overPerformerData}
          graphArrow={"up"}
          cardName="Most Overperformer"
          graphImage={graphup}
        />
        <ReportCard
          type="error"
          data={underPerformerData}
          graphArrow={"down"}
          cardName="Most Underperformers"
          graphImage={graphdown}
        />
        <ReportCard
          type="success"
          data={aboveTargetData}
          graphArrow={"up"}
          cardName="Above Target"
          graphImage={graphup}
        />
        <ReportCard
          type="error"
          data={belowTagetData}
          graphArrow={"down"}
          cardName="Below Target"
          graphImage={graphdown}
        />
      </Row>
    </div>
  );
};

export default Overview;
