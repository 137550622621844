import React, { useEffect, useState } from "react";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { AiOutlineHome } from "react-icons/ai";
import { IoChevronForwardOutline } from "react-icons/io5";
import { LoadingOutlined } from "@ant-design/icons";
import "../../styles/pages/generateReport.css";
import { Button, Form, Input, notification, Select, Spin } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import GeneratorCard from "../../components/Modals/generatorCard/generatorCard";
import {
  getAllClass,
  getClassSubjects,
  getStudentAll,
  getStudentsOnClassSubject,
} from "../../store/services/teacher";
import { createReport } from "../../store/services/report";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { useNavigate } from "react-router-dom";

const GenerateReport = () => {
  const Navigate = useNavigate();
  const [form] = Form.useForm();
  const [classes, setClasses] = useState<any>(null);
  const [subjects, setSubjects] = useState<any>(null);
  const [load, setLoad] = useState(false);
  const [students, setStudents] = useState<any>(null);
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const [selectedSubject, setSelectedSubject] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [isStudentEmpty, setIsStudentEmpty] = useState<any>(null);
  const navigate = useNavigate();

  const [points, setPoints] = useState({
    effort: 1,
    attendance: 1,
    punctuality: 1,
    uniform: 1,
    planner: 1,
  });

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: "#ffffff" }} spin />
  );
  useEffect(() => {
    const getTeacherClasses = async () => {
      const res = await getAllClass();

      if (res) {
        setClasses(res?.data?.classes);
      }
    };

    getTeacherClasses();
  }, []);

  useEffect(() => {
    if (classes && classes?.length > 0) {
      setSelectedClass(classes[0]);
      getTeacherSubjects(classes[0]?.classId);
    }
  }, [classes]);

  const getTeacherSubjects = async (classId: number) => {
    setLoad(true);
    const res = await getStudentAll({ classId: classId });
    setLoad(false);
    if (res) {
      // setSubjects(res?.data?.subjects);
      // setSelectedSubject(res?.data?.subjects?.[0]);
      setStudents(res.data.lists);
      // getStudents(classId, res?.data?.subjects?.[0]?.subjectId);
    }
  };

  const getStudents = async (classId: number, subjectId: number) => {
    const res = await getStudentsOnClassSubject(classId, subjectId);
    if (res) {
      setStudents(res?.data?.students);
    }
  };

  const submitHandler = async () => {
    const formValues = form.getFieldsValue();
    const dataToValidate = {
      ...formValues,
      classId: selectedClass?.classId,
      // subjectId: selectedSubject?.subjectId,
    };
    const hasEmptyField = Object.values(dataToValidate).some(
      (value) => value === undefined || value === null || value === ""
    );
    if (hasEmptyField) {
      notification.error({
        message: "please fill all the feild",
      });
      return;
    }

    setLoader(true);
    const res = await createReport({
      ...dataToValidate,
      ...points,
    });

    if (res) {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
      Navigate("/reports");
    }
  };

  useEffect(() => {}, [isStudentEmpty]);

  return checkRoutePermissions("generateReport") ? (
    <div className="mainDiv responsive">
      <div>
        <div className="std_reports_main">
          <AiOutlineHome color="#26252A" className="std_reports_icon" />
          <IoChevronForwardOutline />
          <div className="std_reports_text" onClick={() => navigate(-1)}>
            Reports
          </div>
          <IoChevronForwardOutline />
          <div className="std_reports_text std_reports_text_head">
            Generate report
          </div>
        </div>
      </div>
      <div className="generate_main">
        <div className="generate_text">Generate report</div>
      </div>

      <div className="learning_btn_tabs_parent">
        {classes?.length > 0 &&
          classes.map((item: any) => {
            return (
              <div
                onClick={() => {
                  form.resetFields();
                  setStudents([]);
                  setSelectedClass(item);
                  getTeacherSubjects(item?.classId);
                  setIsStudentEmpty(null);
                }}
                className={`learn_btn_tabs_child ${
                  item.classIndex === selectedClass?.classIndex &&
                  "learn_btn_tabs_child_active"
                } `}
              >
                {`${item?.className}`}
              </div>
            );
          })}
      </div>

      {/* <div className="learning_btn_tabs_parent" style={{ marginTop: "2rem" }}>
        {load ? (
          <Spin />
        ) : (
          subjects?.length > 0 &&
          subjects.map((item: any) => {
            return (
              <div
                onClick={
                  isStudentEmpty == null || undefined
                    ? () => {
                        form.resetFields();
                        setStudents([]);
                        setSelectedSubject(item);
                        getStudents(selectedClass?.classId, item?.subjectId);
                      }
                    : undefined
                }
                className={`learn_btn_tabs_child ${
                  item.subjectId === selectedSubject?.subjectId &&
                  "learn_btn_tabs_child_active"
                } `}
                style={{
                  opacity: isStudentEmpty == null || undefined ? 1 : 0.5,
                  cursor:
                    isStudentEmpty == null || undefined
                      ? "pointer"
                      : " not-allowed",
                }}
              >
                {item?.subjectName}
              </div>
            );
          })
        )}
      </div> */}

      <div className="generate_main">
        <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Form.Item name="title" label="Report Title">
            <Input type="text" placeholder="Name..." />
          </Form.Item>
          <Form.Item name="studentId" label="Choose student">
            <Select
              allowClear
              className="_studentSelectBox"
              showSearch
              placeholder="Search Student..."
              optionFilterProp="label"
              onChange={(e) => setIsStudentEmpty(e)}
              options={students?.map((item: any) => {
                return {
                  value: item?.id,
                  label: `${item?.firstName} ${item?.LastName}`,
                };
              })}
            />
          </Form.Item>
          <Form.Item name="endDate" label="Date">
            <Input type="date" placeholder="Select the date" />
          </Form.Item>
          <h1 className="generator_text">Teacher Comments:</h1>
          <Form.Item name="overview" label="">
            <TextArea
              showCount
              maxLength={300}
              // onChange={onChange}
              placeholder="Add a comments.."
              style={{ height: 120, resize: "none" }}
            />
          </Form.Item>
        </Form>
        {/* <h1 className="generator_text">Student Overview</h1>
        <div>
          <TextArea
            showCount
            maxLength={300}
            // onChange={onChange}
            placeholder="Add a comments.."
            style={{ height: 120, resize: "none" }}
          />
        </div> */}
        <div>
          <GeneratorCard
            name="Effort /focus"
            identifier={"effor"}
            setPoints={setPoints}
          />
          <GeneratorCard
            name="Attendance"
            identifier={"attendance"}
            setPoints={setPoints}
          />
          <GeneratorCard
            name="Punctuality"
            identifier={"punctuality"}
            setPoints={setPoints}
          />
          <GeneratorCard
            name="Uniform"
            identifier={"uniform"}
            setPoints={setPoints}
          />
          <GeneratorCard
            name="Planner"
            identifier={"planner"}
            setPoints={setPoints}
          />
          <div className="generate_btn_parent">
            <Button className="generate_btn_cancel" loading={loader}>
              Cancel
            </Button>
            <Button className="generate_btn_submit" onClick={submitHandler}>
              {loader ? (
                <Spin
                  indicator={loadingIcon}
                  size="small"
                  className="reportSpin"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default GenerateReport;
