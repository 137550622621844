import { useEffect, useState } from "react";
import { LuInfo } from "react-icons/lu";
import AvatarRoundIcons from "../AvatarRoundIcons";
import StudentBrief from "./StudentBrief";
import ProgressCard from "../ProgressCard";
import BarChartComp from "../Chart/BarChartComp";
import NeedleMeterChart from "../Chart/NeedleMeterChart";
import {
  fetchBadge,
  getSingleStudentState,
  getStudentPerfomanceByMonth,
  getSubjectsStudent,
} from "../../store/services/student";
import { Select } from "antd";
import { RiExpandUpDownLine } from "react-icons/ri";
import Loading from "../Loading/Loading";
import { getStudentCurrentLevel } from "../../store/services/report";

// import { Bar } from '@ant-design/charts';

function Overview({ studentData }: any) {
  const [primaryColor, setPrimaryColor] = useState("var(--primary-color)");
  const [singleStudent, setSingleStudent] = useState([]);
  const [performanceByMonth, setPerformanceByMonth] = useState([]);
  const [subjects, setSubjects] = useState<any>([]);
  const [badge, setBadge] = useState<string>("");
  const [currentLevelProgress, setCurrentLevelProgress] = useState<any>(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState<number | null>(
    null
  );
  const [currentLevelProgressLoading, setCurrentLevelProgressLoading] =
    useState<boolean>(false);
  const [performanceByMonthLoading, setPerformanceByMonthLoading] =
    useState<boolean>(false);
  const getPerformanceByMonth = async () => {
    try {
      setPerformanceByMonthLoading(true);
      const res = await getStudentPerfomanceByMonth({
        studentId: studentData?.id,
        classId: studentData?.classId,
      });
      if (res) setPerformanceByMonth(res);
      setPerformanceByMonthLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getSubjects = async () => {
    try {
      setCurrentLevelProgressLoading(true);
      const res = await getSubjectsStudent({
        studentId: studentData?.id,
      });
      if (res) setSubjects(res?.result);
    } catch (err) {
      console.log(err);
    }
  };
  const getBadge = async () => {
    try {
      setCurrentLevelProgressLoading(true);
      const res = await fetchBadge({
        studentId: studentData?.id,
      });
      if (res) setBadge(res?.badge);
    } catch (err) {
      console.log(err);
    }
  };

  const getCurrentLevelBySubject = async () => {
    try {
      setCurrentLevelProgressLoading(true);
      const res: any = await getStudentCurrentLevel({
        studentId: studentData?.id,
        subjectId: selectedSubjectId,
      });
      if (res) setCurrentLevelProgress(res?.data.result);
      setCurrentLevelProgressLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getSingleStudent = async () => {
      const res = await getSingleStudentState({
        studentId: studentData?.id,
        classId: studentData?.classId,
      });
      if (res) setSingleStudent(res?.data?.result);
    };
    getBadge();
    getSubjects();
    getSingleStudent();
    getPerformanceByMonth();
  }, [studentData]);

  useEffect(() => {
    // Dynamically get the CSS variable --primary-color from the :root
    const rootStyle = getComputedStyle(document.documentElement);
    const primaryColorValue = rootStyle.getPropertyValue(
      "var(--primary-color)"
    );
    // Update the state with the CSS variable value if it's available
    if (primaryColorValue) {
      setPrimaryColor(primaryColorValue.trim());
    }
    //   if(studentData){
    // const subName =  studentData?.subjects.map((sub:any)=>{
    //         return sub.subjectName
    //     })
    //     console.log(subName);
    //   }
  }, [studentData]);

  useEffect(() => {
    if (subjects?.length > 0) {
      setSelectedSubjectId(subjects[0].subjectId);
    }
  }, [subjects]);

  useEffect(() => {
    if (selectedSubjectId !== null) {
      getCurrentLevelBySubject();
    }
  }, [selectedSubjectId]);

  // const BarChartComponent = (data:any) => {
  //   const config = {
  //     data,
  //     xField: 'thisMonth', // Data key for X-axis
  //     yField: 'name', // Data key for Y-axis
  //     seriesField: 'month', // If you have a series field
  //     isStack: true,
  //     // Custom colors for the bars
  //     color: ['var(--primary-color)', 'var(--secondary-color)'],
  //     // Customize axes
  //     xAxis: {
  //       tickCount: 5,
  //       title: {
  //         text: 'Count',
  //         style: {
  //           fontSize: 14,
  //         },
  //       },
  //     },
  //     yAxis: {
  //       title: {
  //         text: 'Subjects',
  //         style: {
  //           fontSize: 14,
  //         },
  //       },
  //       label: {
  //         autoHide: false, // Ensure all names are shown
  //       },
  //     },
  //     legend: {
  //       position: 'top-left', // Position of the legend
  //     },
  //     tooltip: {
  //       showTitle: true,
  //       title: 'Data',
  //     },
  //   };

  //   return <Bar {...config} />;
  // };

  return (
    <div className="_overviewDashboardWrapper">
      <div className="_overviewDashboard">
        {/* ----------> Left Section <----------*/}
        <div className="_leftSectionView">
          <div className="_leftSectionWrapper">
            {/* Chart Section */}
            <div className="_chartSection">
              <div className="_chartBox">
                <p className="_chartHeading">Level</p>
                <div className="_chart">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          backgroundColor: "var(--primary-color)",
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />
                      <span>Level</span>
                    </div>
                    <div>
                      {subjects?.length > 0 && (
                        <Select
                          className="filter_select"
                          placeholder="Select a subject"
                          optionFilterProp="children"
                          value={selectedSubjectId}
                          onChange={(e) => setSelectedSubjectId(e)}
                          suffixIcon={
                            <RiExpandUpDownLine
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#667085",
                              }}
                            />
                          }
                        >
                          {subjects.map((item: any, index: number) => {
                            return (
                              <Select.Option key={index} value={item.subjectId}>
                                {item.subjectName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                    </div>
                  </div>
                  {currentLevelProgressLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "70%",
                        height: "70%",
                      }}
                    >
                      <Loading />
                    </div>
                  ) : (
                    currentLevelProgress && (
                      <>
                        {" "}
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "10px",
                            color: "black",
                          }}
                        >
                          Current Level:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {currentLevelProgress?.levelName} -{" "}
                            {Number(currentLevelProgress?.percentage).toFixed(
                              0
                            )}
                            %
                          </span>
                        </p>
                        <div style={{ minWidth: "340px" }}>
                          <NeedleMeterChart
                            percentage={currentLevelProgress?.percentage}
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="_chartBox">
                <p className="_chartHeading">Performance Targets Met</p>
                <div className="_chart">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {/* <BarChartComponent data={data} /> */}
                    {performanceByMonthLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Loading />
                      </div>
                    ) : (
                      <BarChartComp data={performanceByMonth} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <StudentBrief studentData={studentData} />
            </div>
          </div>
          <div style={{ height: "20px" }} />
        </div>

        {/* ----------> Right Section <----------*/}
        <div className="_rightSection">
          <div className="_overviewDetails">
            <h2>Student Profile</h2>
            <div className="_studentImage">
              <img
                src={
                  studentData?.avatar
                    ? studentData?.avatar
                    : require("../../assets/attachment.png")
                }
                alt={"Student"}
              />
              <AvatarRoundIcons />
            </div>
            <div className="_studentNameBox">
              <h3>
                {studentData?.name} {studentData?.Lastname}
              </h3>
              {badge === "least" ? (
                <div className="_underperformance">
                  <LuInfo style={{ marginBottom: "-2px" }} /> Underperfomance
                </div>
              ) : badge === "top" ? (
                <div className="_overperformance">
                  <LuInfo style={{ marginBottom: "-2px" }} /> Overperfomance
                </div>
              ) : (
                ""
              )}
            </div>
            <p>Student</p>
            <div className="_classAndContact">
              <p>
                <LuInfo style={{ marginBottom: "-2px", marginRight: "3px" }} />{" "}
                {studentData?.class || "N/A"}
              </p>
            </div>
          </div>
          <div className="_classesProgressSection">
            <h2>Classes Progress</h2>
            <div className="_classesProgressBox">
              {singleStudent && singleStudent.length > 0 ? (
                singleStudent.map((v, i) => {
                  return <ProgressCard key={i} data={v} />;
                })
              ) : (
                <p>No class and subject assigned</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
