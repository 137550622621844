import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Progress,
  Select,
  Table,
  Modal,
  Form,
  Space,
} from "antd";

import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { IoTrashOutline } from "react-icons/io5";

// @ts-ignore
import addstudent from "../../assets/addstudent.png";

import CustomPagination from "../../components/Pagination/CustomPagination";
import { useAppSelector } from "../../store/hooks";
import { getAboveTarget } from "../../store/services/state";

import moulviAvatar from "../../../src/assets/user-icon.png";
import useDebounce from "../../Hook/useDebounce";
import { getAllClasses } from "../../store/services/class";
import { getSubjectAll } from "../../store/services/subject";

const columns = [
  {
    title: "Name",
    dataIndex: "studentName",
    key: "name",
    width: "22%",
    render: (a: any, b: any) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={b?.avatar ? b?.avatar : moulviAvatar}
            alt="avatar"
            style={{ width: "40px", height: "40px" }}
          />
          <p style={{ fontSize: "14px" }}>{a}</p>
        </div>
      );
    },
  },
  // {
  //   title: 'Last Name',
  //   dataIndex: 'lastname',
  //   key: 'lastname',
  //   width: '22%',
  // },
  {
    title: "Class",
    dataIndex: "classIndex",
    key: "class",
    width: "22%",
    render(a: any) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <p style={{ fontSize: "14px" }}> Class {a}</p>
        </div>
      );
    },
  },
  {
    title: "Subjects",
    dataIndex: "subjectName",
    key: "subject",
    width: "22%",
  },
  {
    title: "At level",
    dataIndex: "levelName",
    key: "levelName",
    width: "22%",
  },

  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
    render: () => {
      return (
        <div
          style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
        >
          {/* <FiEdit2 style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> */}
          <IoTrashOutline
            style={{
              width: "20px",
              height: "20px",
              color: "#D92D20",
              cursor: "not-allowed",
              opacity: 0.5,
            }}
          />
        </div>
      );
    },
  },
];

const StudentAboveTarget = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const onSelectChange = (e: any) => {
    // console.log('e', e)
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const { classes, count } = useAppSelector((state) => state.class);
  const { aboveTarget, loading } = useAppSelector((state) => state.states);
  const [filterState, setFilterState] = useState({
    search: null,
    classId: null,
    subjectId: null,
  });

  console.log("classes", classes);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getAllClasses();
    const getSubjects = async () => {
      const res = await getSubjectAll();

      if (res) {
        setSubjects(res?.subjectList);
      }
    };

    getSubjects();
  }, []);

  useEffect(() => {
    const getAboveTargetStudent = async () => {
      const res = await getAboveTarget(
        filterState?.search,
        filterState?.classId,
        filterState?.subjectId
      );
    };
    getAboveTargetStudent();
  }, [filterState]);
  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);
  const [form] = Form.useForm();

  const filterHandle = (value: any, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value === "all" ? null : value,
    }));
  };

  return (
    <div className="teachers_container">
      <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
      >
        <img src={addstudent} alt="logo" />
        <div className="modalheading">
          <h1>Add Student</h1>
          <div className="pdiv">
            <p className="modoalp">
              Fill out the following information to add a new student to the
              system.
            </p>
          </div>
        </div>
        <div className="form">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name="name" label="Fist Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="last name" label="Last Name" className="labels">
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item name="class" label="Select Class" className="labels">
              <Select
                // className='filter_select'
                placeholder="Class 1"
                optionFilterProp="children"
                onChange={onSelectChange}
                suffixIcon={
                  <RiExpandUpDownLine
                    style={{ width: "20px", height: "20px", color: "#667085" }}
                  />
                }
                options={[
                  {
                    value: "1",
                    label: "Class 1",
                  },
                  {
                    value: "2",
                    label: "Class 2",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Progress percent={60} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button className="cancelbtn">Cancel</Button>
                <Button htmlType="reset" className="addbtn">
                  Add
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <div className="fliters_container">
        <Input
          placeholder="Search..."
          prefix={
            <CiSearch
              style={{ width: "20px", height: "20px", color: "#667085" }}
            />
          }
          className="filter_search"
          onChange={(e) => debouncedFunction(e.target.value, "search")}
        />

        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="Class 1"
            optionFilterProp="children"
            onChange={(e) => filterHandle(e, "classId")}
            suffixIcon={
              <RiExpandUpDownLine
                style={{ width: "20px", height: "20px", color: "#667085" }}
              />
            }
            options={[
              { value: "all", label: "All Classes" },
              ...classes?.map((value: any) => {
                return {
                  value: value.classId,
                  label: value.className,
                };
              }),
            ]}
          />
        </div>

        <div className="select_container">
          <Select
            className="filter_select"
            placeholder="All Subjects"
            optionFilterProp="children"
            // onChange={onSelectChange}
            onChange={(e) => filterHandle(e, "subjectId")}
            suffixIcon={
              <RiExpandUpDownLine
                style={{ width: "20px", height: "20px", color: "#667085" }}
              />
            }
            options={[
              { value: "all", label: "All Subjects" },
              ...subjects?.map((value: any) => {
                return {
                  value: value.subjectId,
                  label: value.subjectName,
                };
              }),
            ]}
          />
        </div>

        {/* <div className='select_container'>
          <Select
            className='filter_select'
            placeholder='Level 2'
            optionFilterProp='children'
            onChange={onSelectChange}
            suffixIcon={
              <RiExpandUpDownLine style={{ width: '20px', height: '20px', color: '#667085' }} />
            }
            options={[
              {
                value: '1',
                label: 'Level 1',
              },
              {
                value: '2',
                label: 'Level 2',
              },

            ]}
          />
        </div>

        <div className='select_container'>
          <Select
            className='filter_select'
            placeholder='Current year'
            optionFilterProp='children'
            onChange={onSelectChange}
            suffixIcon={
              <RiExpandUpDownLine style={{ width: '20px', height: '20px', color: '#667085' }} />
            }
            options={[
              {
                value: '1',
                label: 'Level 1',
              },
              {
                value: '2',
                label: 'Level 2',
              },

            ]}
          />
        </div> */}
      </div>

      <div className="table_container">
        <Table
          className="custom_table_design"
          dataSource={aboveTarget?.slice(currentPage * 8 - 8, currentPage * 8)}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </div>
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={aboveTarget?.length}
      />
    </div>
  );
};

export default StudentAboveTarget;
